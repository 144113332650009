import React, { useState, useEffect, useContext } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Link, useParams } from "react-router-dom";
import API from "../../../API/Network";
import { baseURL } from "../../../Config/env";

const Preview = () => {
  const [data, setData] = useState({});
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    API.get(`/documents/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  console.log(data.path);
  const url = `${baseURL}/storage/${data.path}`;
  return (
    <React.Fragment>
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div className="card custom-card mg-b-20" id="tabs-style2">
            <div className="card-body">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.js">
                <div
                  style={{
                    height: "750px",
                    maxWidth: "900px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Viewer
                    fileUrl={url}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              </Worker>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Preview;
