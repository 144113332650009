import React, { useState, useEffect, useContext } from "react";
import API from "../../API/Network";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import { PermissionContext } from "../../Services/PermissionContext";
import DataTable from "react-data-table-component";
import customStyles from "../../utils/datatables/customStyles";
import { baseURL } from "../../Config/env";

const Courrier = () => {
  const [courriers, setCourriers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [setting, setSetting] = useState({});

  const navigate = useNavigate();

  const { id } = useParams();

  const checkPermissions = useContext(PermissionContext);

  const getData = () => {
    API.get(`get-courriers-by-directory/${id}`)
      .then((res) => {
        setCourriers(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/settings")
      .then((res) => {
        setSetting(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = courriers.filter(
    (item) =>
      item.motif.toLowerCase().includes(searchText.toLowerCase()) ||
      item.numero_accuse.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      cell: (row) => (
        <div>
          {setting.global_ref}
          {setting.global_ref && <span>/</span>}
          {row.numero_accuse}
        </div>
      ),
      name: "Numéro accusé",
      sortable: true,
    },
    {
      name: "Date réception",
      selector: "date_reception",
      sortable: true,
      cell: (row) => <div>{row.date_reception}</div>,
    },

    {
      name: "Observation",
      selector: "observation",
      sortable: true,
    },
    {
      name: "Motif",
      selector: "motif",
      sortable: true,
    },
    {
      name: "expediteur",
      selector: "expediteur",
      sortable: true,
      width: "200px",
    },
    {
      name: "Destinataire",
      selector: "destinataire",
      sortable: true,
      width: "300px",
      cell: (row) => <div>{row.user.contact.full_name}</div>,
    },
    {
      name: "Fichier",
      selector: "doc",
      sortable: true,
      width: "300px",

      cell: (row) => (
        <a
          href={`${baseURL}/storage/docs/${row.doc}`}
          target="_blank"
          title="Cliquer pour voir le courrier"
          rel="noopener noreferrer"
        >
          {row.doc}
        </a>
      ),
    },

    {
      name: "Action",
      auto: true,
      width: "530px",

      cell: (row) => (
        <div className="d-flex">
          {!row.status_courrier
            ? checkPermissions.includes("affecter un courier") && (
                <Link to={`/courrier/${row.id}`} className="btn btn-info mx-2">
                  Affecter
                </Link>
              )
            : checkPermissions.includes("affecter un courier") && (
                <button disabled className="btn btn-primary mx-2">
                  Déjà affecté
                </button>
              )}
          <Link to={`/courrier-barcode/${row.id}`} className="btn btn-info">
            imprimer barre code
          </Link>
          <Link
            to={`/history-courrier/${row.id}`}
            className="btn btn-primary mx-2"
          >
            suivi courrier
          </Link>
          {!row.status_courrier
            ? checkPermissions.includes("supprimer un courrier redigé") && (
                <button
                  className="btn btn-danger"
                  onClick={(e) => deleteCourrier(row)}
                >
                  Supprimer
                </button>
              )
            : checkPermissions.includes("supprimer un courrier redigé") && (
                <button disabled className="btn btn-danger">
                  Supprimé
                </button>
              )}
        </div>
      ),
    },
  ];

  const deleteCourrier = (row) => {
    API.delete(`/courriers/${row.id}`)
      .then((res) => {
        Swal.fire({
          title: "Suppression",
          type: "error",
          text: "courrier supprimé !",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(courriers);
  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Courriers entrants
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dasboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    liste des Courriers entrants
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  {checkPermissions.includes("scanner le courrier entrant") && (
                    <Link
                      to={`/subdirectories/${id}/createCourrier`}
                      className="btn btn-primary my-2 btn-icon-text"
                    >
                      Identification courrier
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div class="row row-sm">
              <div className="col-4 mb-3">
                <input
                  type="text"
                  className="form-control border-end-0 border rounded-pill"
                  placeholder="Recherche..."
                  onChange={handleSearch}
                />
              </div>
              <div class="col-lg-12">
                <div class="card custom-card overflow-hidden">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination={true}
                          progressPending={isLoading}
                          progressComponent={<Loader />}
                          fixedHeader={true}
                          striped
                          highlightOnHover
                          pointerOnHover
                          responsive
                          customStyles={customStyles}
                          noDataComponent={
                            <div className="alert alert-warning">
                              Il n'y a pas d'enregistrements à afficher
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Courrier;
