import React, { useEffect, useState } from "react";
import API from "../../../API/Network";
import Loader from "../../Loader";
import { Link } from "react-router-dom";

const Folder = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    API.get("/folders")
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          console.log(error.response.data.errors);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Dossiers</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste des dossiers
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              {isLoading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : data.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card custom-card border p-0 shadow-none">
                      <div class="d-flex align-items-center px-4 pt-2">
                        <label class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            name="example-checkbox2"
                            value="option2"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                      </div>
                      <div class="card-body pt-0 text-center">
                        <Link
                          to={`/folders/${item.id}/subfolders`}
                          class="open-file"
                        >
                          <div class="file-manger-icon">
                            <i className="fe fe-folder fe-folder tx-30 bg-primary p-4 rounded-5"></i>
                          </div>
                          <h6 class="mb-1 mt-4 font-weight-semibold mt-0">
                            {item.name}
                          </h6>
                          <span class="text-muted">
                            {item.nb_subfolders > 1
                              ? "Sous dossiers"
                              : "Sous dossier"}{" "}
                            ({item.nb_subfolders})
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center mt-3">
                  <p className="text-muted">Aucun dossier créé !</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Folder;
