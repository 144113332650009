import React, { Fragment, useState, useEffect } from "react";
import API from "../../../API/Network";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const EditPrefix = () => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState();
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get(`/prefix-refs/${id}}`).then((response) => {
      let prefix = response.data;
      console.log(prefix.name);
      setName(prefix.name);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    API.patch(`prefix-refs/${id}`, {
      name: name,
    })
      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Préfix modifié avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate("/profile");
  }
  return (
    <Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Préfixe</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Editer le préfixe
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center"></div>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div className="card custom-card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="text-center">
                        <div className="form-group col-md-4 offset-md-4">
                          <input
                            onChange={handleNameChange}
                            type="text"
                            value={name}
                            class={`form-control ${
                              errors && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          {errors && errors.name ? (
                            <div className="text-danger">{errors["name"]} </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <p classNameName="text-center">
                          <button className="text-center btn btn-primary">
                            Modifier
                          </button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditPrefix;
