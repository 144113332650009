import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import API from "../../API/Network";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { PermissionContext } from "../../Services/PermissionContext";
import customStyles from "../../utils/datatables/customStyles";

const Role = () => {
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const getData = () => {
    API.get("/roles")
      .then((res) => {
        setRoles(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = roles.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.created_at.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Nom du rôle",
      selector: "name",
      sortable: true,
    },
    {
      name: "Date de Création",
      selector: "created_at",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.created_at}</Moment>
        </div>
      ),
    },
    {
      name: "Date de modification",
      selector: "updated_at",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.updated_at}</Moment>
        </div>
      ),
    },

    {
      name: "Action",
      width: "380px",
      cell: (row) => (
        <div className="d-flex">
          <Link
            to={`/roles/${row.id}/permission-list`}
            className="mx-2 btn btn-info"
          >
            voir les permissions
          </Link>
          {checkPermissions.includes("supprimer un utilisateur") && (
            <button className="btn btn-danger" onClick={(e) => deleteRole(row)}>
              Supprimer
            </button>
          )}
          {checkPermissions.includes("modifier un utilisateur") && (
            <Link to={`/role/${row.id}`} className="mx-2 btn btn-info">
              Editer
            </Link>
          )}
        </div>
      ),
    },
  ];

  const deleteRole = (row) => {
    API.delete(`/roles/${row.id}`)
      .then((res) => {
        Swal.fire({
          title: "Suppression",
          type: "error",
          text: "role supprimé !",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Rôles</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dasboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    liste des Rôles
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  {checkPermissions.includes("créer un utilisateur") && (
                    <p className=" text-end">
                      <Link className="btn btn-primary mb-2" to="/createRole">
                        Ajouter un rôle
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div class="row row-sm">
              <div class="col-lg-12">
                <div className="col-4 mb-3">
                  <input
                    type="text"
                    className="form-control border-end-0 border rounded-pill"
                    placeholder="Recherche..."
                    onChange={handleSearch}
                  />
                </div>
                <div class="card custom-card overflow-hidden">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination={true}
                          progressPending={isLoading}
                          progressComponent={<Loader />}
                          fixedHeader={true}
                          striped
                          highlightOnHover
                          pointerOnHover
                          customStyles={customStyles}
                          noDataComponent={
                            <div className="alert alert-warning">
                              Il n'y a pas d'enregistrements à afficher
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Role;
