import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from "../../API/Network";
import Swal from "sweetalert2";

const Answer = () => {
  const [Data, setData] = useState("");
  const [concerne, setConcerne] = useState("");
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const handleDataChange = (event, editor) => {
    setData(editor.getData());
  };

  const handleConcerneChange = (event) => {
    setConcerne(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let id = params.id;
    API.post(`/answer/${id}`, {
      content: Data,
      concerne: concerne,
    })
      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "la réponse a bien été enregistrée.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          if (error.response.status !== 200) {
            setErrors(error.response.data.errors);
            console.log(error.response.data.errors);
          }
        }
      });
  };

  if (redirect) {
    navigate("/redactions");
  }
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div class="form-group">
          <label class="">Concerne</label>
          <input
            onChange={handleConcerneChange}
            class={`form-control ${
              errors && errors.concerne ? "is-invalid" : ""
            }`}
            placeholder="Entrez l'objet de la lettre"
            type="text"
          />
          {errors && errors.concerne ? (
            <div className="text-danger">{errors["concerne"]} </div>
          ) : (
            ""
          )}
        </div>
        <CKEditor
          editor={ClassicEditor}
          onChange={(event, editor) => {
            handleDataChange(event, editor);
          }}
        />
        {errors && errors.content ? (
          <div className="text-danger">{errors["content"]} </div>
        ) : (
          ""
        )}
        <button className="btn btn-primary mt-2">Enregister</button>
      </form>
    </React.Fragment>
  );
};

export default Answer;
