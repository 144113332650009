import React, { useContext, useEffect, useState } from "react";
import API from "../../../../API/Network";
import Loader from "../../../Loader";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import { PermissionContext } from "../../../../Services/PermissionContext";

const SubFolder = () => {
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { id } = useParams();

  const checkPermissions = useContext(PermissionContext);

  const handleNameChange = (e) => {
    setName(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    API.get(`/folders/${id}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  console.log(data);
  const AddDoc = () => {
    API.post("/folders", {
      name: name,
      parent_id: id,
    })
      .then((res) => {
        handleClose();
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Sous dossiers enregistré avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        setName("");
        setErrors([""]);
        getData();
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  const deleteFolder = (id) => {
    API.delete(`/folders/${id}`)
      .then((res) => {
        Swal.fire({
          title: "Suppression",
          type: "error",
          text: "sous dossier supprimé !",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        if (error) {
          Swal.fire({
            title: "Suppression",
            type: "success",
            text: "ce sous dossier a des documents et  ne peut être supprimé !",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
          getData();
        }
      });
  };

  return (
    <React.Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Sous Dossiers
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste des sous dossiers
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  {checkPermissions.includes("créér un sous dossier") && (
                    <Button variant="primary" onClick={handleShow}>
                      Ajouter un sous dossier
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {isLoading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : data.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card custom-card border p-0 shadow-none">
                      <div class="d-flex align-items-center px-4 pt-2">
                        <label class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            name="example-checkbox2"
                            value="option2"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                        <div class="float-end ms-auto">
                          {checkPermissions.includes(
                            "modifier un sous dossier"
                          ) ||
                          checkPermissions.includes(
                            "supprimer un sous dossier"
                          ) ? (
                            <a
                              href="#"
                              class="option-dots"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fe fe-more-vertical"></i>
                            </a>
                          ) : null}
                          <div class="dropdown-menu dropdown-menu-start">
                            {checkPermissions.includes(
                              "modifier un sous dossier"
                            ) && (
                              <Link
                                class="dropdown-item"
                                to={`/subfolders/${item.id}/edit`}
                              >
                                <i class="fe fe-edit me-2"></i> Editer
                              </Link>
                            )}
                            {checkPermissions.includes(
                              "supprimer un sous dossier"
                            ) && (
                              <a
                                href="#"
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deleteFolder(item.id);
                                }}
                              >
                                <i className="fe fe-trash me-2"></i> Supprimer
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-0 text-center">
                        <Link
                          to={`../subfolders/${item.id}/documents`}
                          class="open-file"
                        >
                          <div class="file-manger-icon">
                            <i className="fe fe-folder fe-folder tx-30 bg-primary p-4 rounded-5"></i>
                          </div>
                          <h6 class="mb-1 mt-4 font-weight-semibold mt-0">
                            {item.name}
                          </h6>
                          <span class="text-muted">
                            {item.nbr_documents}{" "}
                            {item.nbr_documents > 1 ? "documents" : "document"}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center mt-3">
                  <p className="text-muted">Aucun sous dossier créé !</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un sous dossier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h6 class=" mb-2">Nom</h6>
          </div>
          <input
            type="text"
            placeholder="le nom du sous dossier..."
            onChange={handleNameChange}
            className={`form-control ${
              errors && errors.name ? "is-invalid" : ""
            }`}
          />
          {errors && errors.name ? (
            <div className="text-danger">{errors["name"]} </div>
          ) : (
            ""
          )}
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={AddDoc}>
            Ajouter
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SubFolder;
