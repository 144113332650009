import React, { useState, useEffect, useContext } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Link, useParams } from "react-router-dom";
import API from "../../API/Network";
import Answer from "../Forms/Answer";
import DisplayAnswer from "../Courrier/DisplayAnswer";
import Annotation from "../Forms/Annotation";
import { PermissionContext } from "../../Services/PermissionContext";
import Swal from "sweetalert2";
import AffectCourrierToRedaction from "../Forms/Affectation/AffectCourrierToRedaction";
import Moment from "react-moment";
import { baseURL } from "../../Config/env";

const ViewCourrier = () => {
  const [data, setData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const { id } = useParams();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const checkPermissions = useContext(PermissionContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get(`/mail-a-traiter/previewPdf/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/getUser-affected-courrier/${id}`)
      .then((res) => {
        setUserInfo(res.data);
        console.log(userInfo);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/getUser-affected-courrier/${id}`)
      .then((res) => {
        setUserInfo(res.data);
        console.log(userInfo);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const deleteAnnotation = (id) => {
    API.delete(`/annotation/${id}`).then((res) => {
      Swal.fire({
        title: "Suppression",
        type: "error",
        text: "Annotation supprimée !",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });

      fetchData();
    });
  };

  const desaffectCourrierToRedaction = (e) => {
    e.preventDefault();
    API.patch(`desaffect-courrier-to-redaction/${id}`)
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Le courrier a été désaffecté à la rédaction.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        fetchData();
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          console.log(error.response.data.errors);
        }
      });
  };

  const url = `${baseURL}/storage/docs/${data.doc}`;

  return (
    <React.Fragment>
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div className="card custom-card mg-b-20" id="tabs-style2">
            <div className="card-body">
              <div className="text-wrap">
                <div className="example">
                  <div className="panel panel-primary tabs-style-2">
                    <div className=" tab-menu-heading">
                      <div className="tabs-menu1">
                        <ul className="nav panel-tabs main-nav-line">
                          <li>
                            <a
                              href="#viewCourrier"
                              className="nav-link active mt-1"
                              data-bs-toggle="tab"
                            >
                              Voir le courrier
                            </a>
                          </li>
                          {checkPermissions.includes("faire des annotations") &&
                            data.isArchived === 0 && (
                              <li>
                                <a
                                  href="#comment"
                                  className="nav-link mt-1"
                                  data-bs-toggle="tab"
                                >
                                  {data.annotation
                                    ? "Voir l'annotation"
                                    : "Faire l'annotation"}
                                </a>
                              </li>
                            )}
                          {checkPermissions.includes("rediger un courier") &&
                            data.isArchived === 0 && (
                              <li>
                                <a
                                  href="#answer"
                                  className="nav-link mt-1"
                                  data-bs-toggle="tab"
                                >
                                  {data.answer ? "Voir la réponse" : "Répondre"}
                                </a>
                              </li>
                            )}

                          {checkPermissions.includes("affecter un courier") &&
                            data.isArchived === 0 &&
                            data.status_courrier && (
                              <li>
                                <a
                                  href="#affectation"
                                  className="nav-link mt-1"
                                  data-bs-toggle="tab"
                                >
                                  {data.status_courrier.affect_to
                                    ? "Déjà affecté pour la rédaction"
                                    : "Affecter pour la rédaction"}
                                </a>
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                    <div className="panel-body tabs-menu-body main-content-body-right border">
                      <div className="tab-content">
                        <div className="tab-pane active" id="viewCourrier">
                          <div classNameName="table-responsive">
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.js">
                              <div
                                style={{
                                  height: "750px",
                                  maxWidth: "900px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <Viewer
                                  fileUrl={url}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                          </div>
                        </div>
                        <div className="tab-pane" id="answer">
                          {data.answer ? <DisplayAnswer /> : <Answer />}
                        </div>
                        <div className="tab-pane" id="comment">
                          <div className="mt-5 mr-4">
                            {data.annotation ? (
                              <div>
                                <h3>Annotation</h3>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data.annotation.comment,
                                  }}
                                ></p>
                                {checkPermissions.includes(
                                  "modifier une annotation"
                                ) && (
                                  <Link
                                    to={`/annotation-edit/${data.annotation.id}`}
                                    className="btn btn-primary"
                                  >
                                    Modifier
                                  </Link>
                                )}
                                {checkPermissions.includes(
                                  "supprimer une annotation"
                                ) && (
                                  <button
                                    onClick={(e) =>
                                      deleteAnnotation(data.annotation.id)
                                    }
                                    className="mx-1 btn btn-danger"
                                  >
                                    Supprimer
                                  </button>
                                )}
                              </div>
                            ) : (
                              <Annotation />
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="affectation">
                          {data.status_courrier &&
                            (data.status_courrier.affect_to ? (
                              <div className="col-md-5 offset-md-3">
                                <p>
                                  Date de l'affectation :{" "}
                                  <Moment format="DD/MM/YYYY">
                                    {userInfo.updated_at}
                                  </Moment>
                                </p>
                                <p>
                                  Affecté à :{" "}
                                  <span className="fw-bold">
                                    {userInfo.lastname} {userInfo.middlename}{" "}
                                    {userInfo.firstname}
                                  </span>
                                </p>
                                <button
                                  onClick={desaffectCourrierToRedaction}
                                  className="btn btn-danger"
                                >
                                  Désaffecter
                                </button>
                              </div>
                            ) : (
                              <AffectCourrierToRedaction courrier={data} />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCourrier;
