import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from "../../../API/Network";
import Swal from "sweetalert2";

const Annotation = () => {
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const handleCommentChange = (event, editor) => {
    setComment(editor.getData());
  };

  const handleSubmit = (e) => {
    let id = params.id;

    e.preventDefault();
    API.post(`annotation/${id}`, {
      comment: comment,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Annotation pour ce courrier a été enregistré avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate("/courrierATraiter");
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-center">
        <div className="form-group">
          <CKEditor
            editor={ClassicEditor}
            onChange={(event, editor) => {
              handleCommentChange(event, editor);
            }}
          />
          {errors && errors.comment ? (
            <div className="text-danger">{errors["comment"]} </div>
          ) : (
            ""
          )}
        </div>
        <p classNameName="text-center">
          <button className="text-center btn btn-primary">Enregistrer</button>
        </p>
      </div>
    </form>
  );
};

export default Annotation;
