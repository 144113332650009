import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import API from "../../API/Network";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../Config/env";

const PrintRedaction = () => {
  const [data, setData] = useState([]);
  const [cc, setCc] = useState("");
  const [setting, setSetting] = useState([]);
  const [prefixRef, setPrefixRef] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    API.get("/settings")
      .then((response) => {
        setSetting(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    getData();
  }, []);

  const getData = () => {
    API.get(`/redactions/${id}`)
      .then((res) => {
        setData(res.data.redaction);
        setCc(res.data.transmis_copies.info.split(","));
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/prefix-refs")
      .then((res) => {
        setPrefixRef(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // Create styles
  const styles = StyleSheet.create({
    page: {
      display: "flex",
      backgroundColor: "#fff",
      color: "#000",
      fontSize: "13px",
    },
    column: {
      width: "50%",
      padding: 10,
    },
    logo: {
      width: 90,
      height: 80,
      marginLeft: "0",
    },
    signature: {
      width: 90,
      left: "350px",
      height: 80,
    },
    expediteurName: {
      marginTop: "40",
      textAlign: "right",
    },
    concerne: {
      marginTop: "20px",
      marginBottom: "50px",
    },
    content: {
      fontSize: 11,
      textAlign: "justify",
      fontFamily: "Times New Roman",
    },
    date: {
      position: "absolute",
      top: 55,
      right: 0,
    },
    head: {
      position: "absolute",
      top: cc ? 220 : 100,
      right: 0,
      marginLeft: "50px",
      fontSize: "11px",
    },

    place: {
      position: "absolute",
      top: cc ? 265 : 135,
      right: 30,
      textDecoration: "underline",
      fontWeight: "bold",
    },
    cc: {
      position: "absolute",
      top: 100,
      right: 20,
      textDecoration: "underline",
      fontWeight: "bold",
    },

    section: {
      margin: 50,
      padding: 10,
      fontSize: "11px",
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
      fontFamily: "Times New Roman",
    },

    bullet: {
      top: -80,
      left: 330,
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
    },
    bulletPoint: {
      width: 5,
      height: 5,
      borderRadius: 3,
      backgroundColor: "black",
      marginRight: 5,
    },
    bulletText: {
      fontSize: 10,
    },
  });

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.column}>
              <Image
                src={`${baseURL}/storage/settings/${setting.logo}`}
                alt="Logo"
                style={styles.logo}
              />
              <Text>
                N/Réf. :{prefixRef.name}/
                {data.reference && data.reference.count_ref}
              </Text>
              <Text style={styles.concerne}>Concerne. : {data.motif}</Text>
            </View>

            {cc && (
              <React.Fragment>
                <Text style={styles.cc}>Transmis copie pour information à</Text>
                {cc.map((item, index) => (
                  <View style={styles.bullet} key={index}>
                    <View style={styles.bulletPoint} />
                    <Text style={styles.bulletText}>{item}</Text>
                  </View>
                ))}
              </React.Fragment>
            )}
            <Text style={styles.head}>
              {data.civility === "Madame" ? "A Madame " : "A Monsieur"}{" "}
              {data.destinataire}, {"\n"}
              {data.occupation} {"\n"} {setting.name} {", "}
            </Text>
            <Text style={styles.place}>
              {"A "}
              {setting.town}/{setting.city}
            </Text>
            <Text style={styles.date}>
              {setting.city}, le {data.date_reception}
            </Text>
            <div style={{ textAlign: "justify", marginTop: "50px" }}>
              <Html textAlign="justify" style={styles.content}>
                {data.content}
              </Html>
            </div>
            {data.expediteur && (
              <Text style={styles.expediteurName}>
                {data.expediteur.contact.full_name}
              </Text>
            )}
            {data.signature && (
              <Image
                src={`${baseURL}/storage/signatures/${data.signature.image}`}
                alt="Logo"
                style={styles.signature}
              />
            )}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default PrintRedaction;
