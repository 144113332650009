const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      }
    },
    headRow: {
      style: {
        borderTop: '1px solid #dee2e6', // override the table header border
        backgroundColor: '#6259ca',
        color: '#fff' // set the table header background color
      },
    },
    headCells: {
      style: {
        fontWeight: 'bold', // make the table header cells bold
      },
    },
  };

  export default customStyles;