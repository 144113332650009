import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PermissionContext } from "../../Services/PermissionContext";
import { RoleContext } from "../../Services/RoleContext";

const Sidebar = () => {
  const checkPermissions = useContext(PermissionContext);
  const checkRoles = useContext(RoleContext);

  return (
    <React.Fragment>
      <div className="sticky">
        <div className="main-menu main-sidebar main-sidebar-sticky side-menu">
          <div className="main-sidebar-header main-container-1 active">
            <div className="sidemenu-logo">
              <a
                className="main-logo"
                href="/home"
                style={{ pointerEvents: "none" }}
              >
                <img
                  src="/assets/img/brand/LOGO1.png"
                  className="header-brand-img desktop-logo"
                  alt="logo"
                />
                <img
                  src="/assets/img/brand/ICON2.png"
                  className="header-brand-img icon-logo"
                  alt="logo"
                />
                <img
                  src="/assets/img/brand/logo.png"
                  className="header-brand-img desktop-logo theme-logo"
                  alt="logo"
                />
                <img
                  src="/assets/img/brand/icon.png"
                  className="header-brand-img icon-logo theme-logo"
                  alt="logo"
                />
              </a>
            </div>
            <div className="main-sidebar-body main-body-1">
              <div className="slide-left disabled" id="slide-left">
                <i className="fe fe-chevron-left"></i>
              </div>
              <ul className="menu-nav nav">
                <li className="nav-header">
                  <span className="nav-label">Dashboard</span>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                    <i className="ti-home sidemenu-icon menu-icon "></i>
                    <span className="sidemenu-label">Dashboard</span>
                  </Link>
                </li>

                {checkPermissions.includes("créer un utilisateur") && (
                  <React.Fragment>
                    <li className="nav-header">
                      <span className="nav-label">Gestion utilisateurs</span>
                    </li>
                    <li className="nav-item">
                      <Link to="/permissions" className="nav-link with-sub">
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-package sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">Permissions</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/roles" className="nav-link with-sub">
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-briefcase sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">Roles</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/users" className="nav-link with-sub">
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fa fa-group sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">Utilisateurs</span>
                      </Link>
                    </li>
                  </React.Fragment>
                )}
                <React.Fragment>
                  <li className="nav-header">
                    <span className="nav-label">Gestion des Courriers</span>
                  </li>

                  <li className="nav-item">
                    <Link to="/directories" className="nav-link with-sub">
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fe fe-inbox sidemenu-icon menu-icon "></i>
                      <span className="sidemenu-label">Courriers entrants</span>
                    </Link>
                  </li>
                </React.Fragment>

                <li className="nav-item">
                  <Link to="/courrierATraiter" className="nav-link with-sub">
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                    <i className="fe fe-mail sidemenu-icon menu-icon "></i>
                    <span className="sidemenu-label">Courriers à traiter</span>
                  </Link>
                </li>
                {checkPermissions.includes("rediger un courier") && (
                  <li className="nav-item">
                    <Link to="/redactions" className="nav-link with-sub">
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fe fe-file-text sidemenu-icon menu-icon "></i>
                      <span className="sidemenu-label">Rédactions</span>
                    </Link>
                  </li>
                )}

                {checkPermissions.includes("lire un courier sortant") && (
                  <li className="nav-item">
                    <Link to="/outgoing-mails" className="nav-link with-sub">
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fe fe-navigation sidemenu-icon menu-icon "></i>
                      <span className="sidemenu-label">Courriers sortants</span>
                    </Link>
                  </li>
                )}
                {checkPermissions.includes("lire un document") ||
                checkPermissions.includes("création dossier archivé") ||
                checkPermissions.includes("voir les courriers archivés") ? (
                  <React.Fragment>
                    <li className="nav-header">
                      <span className="nav-label">Archivage</span>
                    </li>

                    <li className="nav-item">
                      <Link to="/folders" className="nav-link with-sub">
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fe fe-folder sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">
                          Archive documents
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/courriers-archive"
                        className="nav-link with-sub"
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fe fe-inbox sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">
                          {" "}
                          Archive courriers
                        </span>
                      </Link>
                    </li>
                  </React.Fragment>
                ) : null}

                {checkPermissions.includes("voir la corbeille") ||
                checkPermissions.includes("restaurer un courrier supprimé") ||
                checkPermissions.includes(
                  "restaurer une rédaction supprimée"
                ) ? (
                  <React.Fragment>
                    <li className="nav-header">
                      <span className="nav-label">Corbeille</span>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/documents-corbeille"
                        className="nav-link with-sub"
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fe fe-trash sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">
                          Documents supprimés
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/courriers-corbeille"
                        className="nav-link with-sub"
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fe fe-trash sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">
                          Courriers supprimés
                        </span>
                      </Link>
                    </li>
                  </React.Fragment>
                ) : null}

                {checkPermissions.includes(
                  "voir les logs historiques des documents"
                ) && (
                  <React.Fragment>
                    <li className="nav-header">
                      <span className="nav-label">Historiques des logs</span>
                    </li>

                    <li className="nav-item">
                      <Link to="/activity-logs" className="nav-link with-sub">
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fa fa-history sidemenu-icon menu-icon "></i>
                        <span className="sidemenu-label">Logs</span>
                      </Link>
                    </li>
                  </React.Fragment>
                )}
                {checkPermissions.includes("créer un utilisateur") && (
                  <React.Fragment>
                    {checkRoles.includes("SuperAdmin") && (
                      <React.Fragment>
                        <li className="nav-header">
                          <span className="nav-label">
                            Configuration Générale
                          </span>
                        </li>

                        <li className="nav-item">
                          <Link to="/settings" className="nav-link with-sub">
                            <span className="shape1"></span>
                            <span className="shape2"></span>
                            <i className="fe fe-settings sidemenu-icon menu-icon "></i>
                            <span className="sidemenu-label">Paramètre</span>
                          </Link>
                        </li>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </ul>
              <div className="slide-right" id="slide-right">
                <i className="fe fe-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
