import React, { Fragment } from "react";
import { baseURL } from "../../../Config/env";

const Signature = ({ image }) => {
  return (
    <Fragment>
      <div class="card-body border">
        <div class="demo-gallery">
          <ul id="lightgallery" class="list-unstyled row row-sm">
            <li class="col-sm-6 col-lg-4  offset-4">
              <a href="" class="wd-100p">
                <img
                  class="img-responsive mb-4 wd-100p"
                  src={`${baseURL}/storage/signatures/${image}`}
                  alt="Thumb-1"
                />{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Signature;
