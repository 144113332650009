import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import API from "../../API/Network";
import { PermissionContext } from "../../Services/PermissionContext";
import { baseURL } from "../../Config/env";

const Setting = () => {
  const [setting, setSetting] = useState([]);

  const permissions = useContext(PermissionContext);

  useEffect(() => {
    API.get("settings")
      .then((res) => {
        setSetting(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <React.Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Configuration générale
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Configuration de base
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  {permissions.includes("créer un utilisateur") &&
                    (setting.name ? (
                      <Link
                        to={`/setting-edit/${setting.id}`}
                        className="btn btn-primary my-2 btn-icon-text"
                      >
                        Modifier la Configuration
                      </Link>
                    ) : (
                      <Link
                        to="/settings/basic-config"
                        className="btn btn-primary my-2 btn-icon-text"
                      >
                        Ajouter la Configuration
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Nom</div>
                          <div className="col-6">
                            <span className="text-muted">{setting.name}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Adresse</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.address && setting.address}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Téléphone</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.phone && setting.phone}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Email</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.email && setting.email}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Idnat</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.idnat && setting.idnat}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">RCCM</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.rccm && setting.rccm}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Site web</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.web_site && setting.web_site}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">N° impôt</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.n_impot && setting.n_impot}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 fw-bold">Référence globale</div>
                          <div className="col-6">
                            <span className="text-muted">
                              {setting.global_ref && setting.global_ref}
                            </span>
                          </div>
                        </div>
                      </div>

                      <Fragment>
                        <div className="col-4">
                          {setting.name && (
                            <img
                              class="img-responsive mb-4 wd-100p"
                              src={`${baseURL}/storage/settings/${setting.logo}`}
                              alt="Logo"
                            />
                          )}
                        </div>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Setting;
