import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const Home = () => {
  return (
    <React.Fragment>
        <Header/>
        <Sidebar/>
    </React.Fragment>
  )
}

export default Home