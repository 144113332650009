import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Login";
import Permission from "../Permission";
import Dashboard from "./../Dashboard";
import Role from "./../Role";
import CreatePermission from "../Forms/Permission/CreatePermission";
import EditPermission from "./../Forms/Permission/EditPermission";
import EditRole from "./../Forms/Role/EditRole";
import User from "./../User";
import CreateUser from "./../Forms/User/CreateUser";
import CreateRole from "./../Forms/Role/CreateRole";
import EditUser from "../Forms/User/EditUser";
import Courrier from "../Courrier";
import CreateCourrier from "./../Forms/Courrier/CreateCourrier";
import Setting from "../Setting";
import AffectationCourrier from "./../Forms/Affectation/AffectationCourrier";
import CourrierATraiter from "../Courrier/CourrierATraiter";
import Redaction from "./../Redaction/index";
import ViewCourrier from "./../PDF/viewCourier";
import OutgoingMail from "./../Courrier/OutgoingMail";
import Preview from "../Redaction/Preview";
import Profile from "../Profile";
import BasicConfig from "./../Forms/Setting/BasicConfig";
import PrintRedaction from "./../PDF/PrintRedaction";
import EditRedaction from "./../Forms/Redaction/EditRedaction";
import EditAnswer from "./../Forms/Answer/EditAnswer";
import CreateRedaction from "./../Forms/Redaction/createRedaction";
import EditAnnotation from "./../Forms/Annotation/EditAnnotation";
import EditSetting from "./../Forms/Setting/EditSetting";
import PrintAnswer from "../PDF/PrintAnswer";
import PrintAccuseReception from "./../PDF/PrintAccuseReception";
import MailAffectedToRedaction from "./../MailAffectedToRedaction/index";
import EditPrefix from "./../Profile/PrefixRef/editPrefix";
import Home from "../Home";
import EditFolder from "../Archive/Folder/EditFolder";
import Folder from "./../Archive/Folder/index";
import Document from "../Archive/Document/index";
import PreviewDocs from "./../Archive/Document/Preview";
import LogHistory from "../LogHistory";
import CourrierArchive from "../Archive/CourrierArchive";
import HistoryCourrier from "../Courrier/HistoryCourrier";
import Directory from "../Courrier/Directory";
import Subdirectory from "../Courrier/Directory/Subdirectory";
import EditSubdirectory from "../Courrier/Directory/Subdirectory/EditSubdirectory";
import PreviewPrivateRedaction from "../Redaction/Private/PreviewPrivateRedaction";
import CourrierCorbeille from "../Corbeille/Courrier";
import SubFolder from "../Archive/Folder/SubFolder";
import DocumentDeleted from "../Corbeille/Document";
import PermissionList from "../Role/PermissionList";

const Router = () => {
  const isConnected = !!localStorage.getItem("token");

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/courrier-barcode/:id"
          element={<PrintAccuseReception />}
        />
        <Route path="/print-redaction/:id" element={<PrintRedaction />} />
        <Route path="/print-answer/:id" element={<PrintAnswer />} />
      </Routes>
      {isConnected ? (
        <React.Fragment>
          <Home />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/permissions" element={<Permission />} />
            <Route path="/roles" element={<Role />} />
            <Route
              path="/roles/:id/permission-list"
              element={<PermissionList />}
            />
            <Route path="/settings" element={<Setting />} />
            <Route path="/createPermission" element={<CreatePermission />} />
            <Route path="/directories" element={<Directory />} />
            <Route
              path="/directories/:id/subdirectories"
              element={<Subdirectory />}
            />

            <Route
              path="/subdirectories/:id/courriers"
              element={<Courrier />}
            />

            <Route
              path="/subdirectories/:id/edit"
              element={<EditSubdirectory />}
            />
            <Route
              path="/subdirectories/:id/createCourrier"
              element={<CreateCourrier />}
            />
            <Route path="/createRole" element={<CreateRole />} />
            <Route path="/createUser" element={<CreateUser />} />
            <Route path="/createCourrier" element={<CreateCourrier />} />
            <Route path="/permission/:id" element={<EditPermission />} />
            <Route path="/role/:id" element={<EditRole />} />
            <Route path="/user/:id" element={<EditUser />} />
            <Route path="/users" element={<User />} />
            <Route path="/courriers" element={<Courrier />} />
            <Route path="/courrier/:id" element={<AffectationCourrier />} />

            <Route path="/courrierATraiter" element={<CourrierATraiter />} />
            <Route path="/redactions" element={<Redaction />} />
            <Route path="/redaction-edit/:id" element={<EditRedaction />} />
            <Route path="/answer-edit/:id" element={<EditAnswer />} />
            <Route path="/annotation-edit/:id" element={<EditAnnotation />} />
            <Route path="/redaction/:id" element={<Preview />} />
            <Route
              path="/preview-private-redaction/:id"
              element={<PreviewPrivateRedaction />}
            />
            <Route path="/createRedaction" element={<CreateRedaction />} />
            <Route path="/viewCourrier/:id" element={<ViewCourrier />} />
            <Route
              path="/mail-affected-redaction/:id"
              element={<MailAffectedToRedaction />}
            />

            <Route path="/outgoing-mails" element={<OutgoingMail />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-prefix/:id" element={<EditPrefix />} />
            <Route path="/settings/basic-config" element={<BasicConfig />} />
            <Route path="/setting-edit/:id" element={<EditSetting />} />
            <Route path="/activity-logs" element={<LogHistory />} />

            {/* ARCHIVE */}
            <Route path="/folders" element={<Folder />} />
            <Route path="/folders/:id/subfolders" element={<SubFolder />} />
            <Route path="/subfolders/:id/documents" element={<Document />} />
            <Route path="/subfolders/:id/edit" element={<EditFolder />} />
            <Route path="/folders/:id" element={<Document />} />
            <Route path="/document/:id" element={<PreviewDocs />} />
            <Route path="/courriers-archive" element={<CourrierArchive />} />

            {/* corebeille */}
            <Route
              path="/courriers-corbeille"
              element={<CourrierCorbeille />}
            />

            <Route path="/documents-corbeille" element={<DocumentDeleted />} />

            {/* history courrier */}
            <Route path="/history-courrier/:id" element={<HistoryCourrier />} />
          </Routes>
        </React.Fragment>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      )}
    </React.Fragment>
  );
};

export default Router;
