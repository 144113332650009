import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from "../../../API/Network";
import Swal from "sweetalert2";
import Home from "../../Home";
import { PermissionContext } from "../../../Services/PermissionContext";

const EditAnswer = () => {
  const [Data, setData] = useState("");
  const [concerne, setConcerne] = useState("");
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const handleDataChange = (event, editor) => {
    setData(editor.getData());
  };

  const handleConcerneChange = (event) => {
    setConcerne(event.target.value);
  };

  useEffect(() => {
    fetchAnswer();
  }, []);

  const fetchAnswer = () => {
    API.get(`/answer/${id}`).then((response) => {
      let answer = response.data;
      setConcerne(answer.concerne);
      setData(answer.content);
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    API.patch(`/answer/${id}`, {
      content: Data,
      concerne: concerne,
    })
      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "la réponse a bien été Modifiée.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          if (error.response.status !== 200) {
            setErrors(error.response.data.errors);
            console.log(error.response.data.errors);
          }
        }
      });
  };

  if (redirect) {
    navigate("/courrierATraiter");
  }
  return (
    <React.Fragment>
      <Home />
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-card">
              <div class="card-body">
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label class="">Concerne</label>
                    <input
                      value={concerne}
                      onChange={handleConcerneChange}
                      class={`form-control ${
                        errors && errors.concerne ? "is-invalid" : ""
                      }`}
                      placeholder="Entrez l'objet de la lettre"
                      type="text"
                    />
                    {errors && errors.concerne ? (
                      <div className="text-danger">{errors["concerne"]} </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={Data}
                    onChange={(event, editor) => {
                      handleDataChange(event, editor);
                    }}
                  />
                  {errors && errors.content ? (
                    <div className="text-danger">{errors["content"]} </div>
                  ) : (
                    ""
                  )}
                  {checkPermissions.includes("modifier un courrier redigé") && (
                    <button className="btn btn-primary mt-2">Modifier</button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAnswer;
