import React, { Fragment, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import Swal from "sweetalert2";
import { baseURL } from "../../../Config/env";
import { useNavigate } from "react-router-dom";

const CreateSignature = () => {
  const signatureRef = useRef();
  const [errors, setErrors] = useState();
  const [redirect, setRedirect] = useState(false);
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);

  const navigate = useNavigate();

  const clearSignature = (e) => {
    e.preventDefault();
    signatureRef.current.clear();
    setIsSignatureDrawn(false);
  };

  const handleSignatureDrawn = () => {
    setIsSignatureDrawn(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.set("image", signatureRef.current.toDataURL());

    let headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(`${baseURL}/api/ebutelo/user-signature`, bodyFormData, headers)
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Signature créée avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    return navigate("/dashboard");
  }

  return (
    <Fragment>
      <form>
        <div className="text-center">
          <div className="form-group col-md-4 offset-md-4 signature-container border border-secondary rounded p-3 mb-3">
            <SignatureCanvas
              ref={signatureRef}
              penColor="black"
              onEnd={handleSignatureDrawn}
              canvasProps={{
                width: 300,
                height: 200,
                className: `${errors && errors.signature ? "is-invalid" : ""}`,
              }}
            />
            {errors && errors.signature ? (
              <div className="text-danger">{errors["signature"]} </div>
            ) : (
              ""
            )}

            {!isSignatureDrawn && (
              <div className="signature-placeholder position-absolute top-50 start-50 translate-middle text-secondary">
                <p className="m-0">Signez ici</p>
              </div>
            )}
          </div>
          <p classNameName="text-center">
            <button
              onClick={handleSubmit}
              className="text-center btn btn-primary mx-2"
              disabled={!isSignatureDrawn}
            >
              Enregistrer
            </button>
            {isSignatureDrawn && (
              <button
                className="text-center btn btn-danger"
                onClick={clearSignature}
              >
                Supprimer
              </button>
            )}
          </p>
        </div>
      </form>

      {/* {trimmedDataURL ? <img src={trimmedDataURL} /> : null} */}
    </Fragment>
  );
};

export default CreateSignature;
