import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditRedaction = () => {
  const [motif, setMotif] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [expediteur, setExpediteur] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [destinataire, setDestinataire] = useState("");
  const [civilite, setCivilite] = useState("");
  const [occupation, setOccupation] = useState("");
  const [errors, setErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleMotifChange = (event) => {
    setMotif(event.target.value);
  };

  const handleDestinataireChange = (event) => {
    setDestinataire(event.target.value);
  };

  const handleExpediteurChange = (event) => {
    setSelectValue(event.target.value);
  };

  const handleCiviliteChange = (event) => {
    setCivilite(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleContentChange = (event, editor) => {
    setContent(editor.getData());
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const civilities = [
    { value: "Monsieur", label: "Monsieur" },
    { value: "Madame", label: "Madame" },
    { value: "Docteur", label: "Docteur" },
  ];

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    API.get("/users")
      .then((res) => {
        setExpediteur(res.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    fetchRedaction();
  }, []);

  const fetchRedaction = () => {
    API.get(`/redactions/${id}`)
      .then((response) => {
        let oldData = response.data.redaction;
        setMotif(oldData.motif);
        setDestinataire(oldData.destinataire);
        setContent(oldData.content);
        setOccupation(oldData.occupation);
        setEmail(oldData.email);
        setCivilite(oldData.civility);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleSumit = (e) => {
    e.preventDefault();

    API.patch(`/redactions/${id}`, {
      motif: motif,
      expediteur: selectValue,
      destinataire: destinataire,
      content: content,
      email: email,
      civility: civilite,
      occupation: occupation,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Rédaction a bien été Modifiée",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate("/redactions");
  }

  return (
    <React.Fragment>
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-card">
              <div class="card-body">
                <form
                  class="form-group"
                  onSubmit={handleSumit}
                  enctype="multipart/form-data"
                >
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">Concerne</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <input
                        value={motif}
                        onChange={handleMotifChange}
                        class={`form-control ${
                          errors && errors.motif ? "is-invalid" : ""
                        }`}
                        placeholder="Entrez le motif"
                        type="text"
                      />
                      {errors && errors.motif ? (
                        <div className="text-danger">{errors["motif"]} </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">Expéditeur</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <select
                        onChange={handleExpediteurChange}
                        class="form-control"
                      >
                        <option>Sélectionner un expéditeur</option>
                        {expediteur.map((user) => (
                          <option value={user.id}>
                            {user.contact.full_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">Civilité</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <select
                        className="form-control select select2"
                        onChange={handleCiviliteChange}
                      >
                        <option>Sélectionner une civilité</option>
                        {civilities.map((civility) => (
                          <option key={civility.value} value={civility.value}>
                            {civility.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">Occupation</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <input
                        value={occupation}
                        onChange={handleOccupationChange}
                        class={`form-control ${
                          errors && errors.occupation ? "is-invalid" : ""
                        }`}
                        placeholder="le poste qu'occupe l'expéditeur"
                        type="text"
                      />
                      {errors && errors.occupation ? (
                        <div className="text-danger">
                          {errors["occupation"]}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">Destinataire</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <input
                        value={destinataire}
                        onChange={handleDestinataireChange}
                        class={`form-control ${
                          errors && errors.destinataire ? "is-invalid" : ""
                        }`}
                        placeholder="Entrez le destinataire"
                        type="text"
                      />
                      {errors && errors.destinataire ? (
                        <div className="text-danger">
                          {errors["destinataire"]}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">
                        Email{" "}
                        <span className="text-muted"> (destinataire)</span>
                      </label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <input
                        value={email}
                        onChange={handleEmailChange}
                        class={`form-control ${
                          errors && errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Entrez le email du destinataire"
                        type="email"
                      />
                      {errors && errors.email ? (
                        <div className="text-danger">{errors["email"]} </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {
                    <CKEditor
                      editor={ClassicEditor}
                      data={content}
                      onChange={(event, editor, data) => {
                        handleContentChange(event, editor, data);
                      }}
                    />
                  }
                  {errors && errors.content ? (
                    <div className="text-danger">{errors["content"]} </div>
                  ) : (
                    ""
                  )}
                  <div class="form-group row justify-content-end mb-0">
                    <div class="col-md-8 ps-md-2 mt-3">
                      <button class="btn ripple btn-primary pd-x-30 mg-r-5">
                        Modifier
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditRedaction;
