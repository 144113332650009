import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";
import { MultiSelect } from "react-multi-select-component";

const CreateRole = () => {
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [selected, setSelected] = useState([]);

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
    console.log(e.target.value);
  };

  const options = permissions.map((permission) => ({
    label: permission.name,
    value: permission.name,
  }));

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  });

  const handleSumit = (e) => {
    e.preventDefault();

    API.post("/roles", {
      name: name,
      permission: selected,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Rôle enregistré avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  useEffect(() => {
    API.get("/permissions")
      .then((res) => {
        setPermissions(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  if (redirect) {
    navigate("/roles");
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="main-content side-content pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card h-100">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Ajouter un rôle
                        </h6>
                        <Link
                          to="/roles"
                          className="text-muted card-sub-title text-primary"
                        >
                          Voir la liste des rôles{" "}
                        </Link>
                      </div>
                      <form onSubmit={handleSumit}>
                        <div className="text-center">
                          <div className="form-group col-md-4 offset-md-4">
                            <input
                              name="role"
                              onChange={handleNameChange}
                              className={`form-control ${
                                errors && errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Saisissez un nom"
                              type="text"
                            />
                            {errors && errors.name ? (
                              <div className="text-danger">
                                {errors["name"]}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="form-group col-md-4 offset-md-4">
                            <p class="mg-b-10">Sélectionner une permission</p>
                            <MultiSelect
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Sélectionner les permissions"}
                              hasSelectAll="Tout sélectionnner"
                              selectAllLabel="Sélectionner tout"
                            />
                          </div>
                          <p classNameName="text-center">
                            <button
                              className="text-center btn btn-primary"
                              style={{ marginBottom: "250px" }}
                            >
                              Ajouter
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default CreateRole;
