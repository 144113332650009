import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Moment from "react-moment";
import API from "../../../API/Network";
import Loader from "../../Loader";
import { PermissionContext } from "../../../Services/PermissionContext";
import DataTable from "react-data-table-component";
import customStyles from "../../../utils/datatables/customStyles";

const DocumentDeleted = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.path.toLowerCase().includes(searchText.toLowerCase()) ||
      item.deleted_at.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const getData = () => {
    API.get("/get-deleted-documents")
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Nom du fichier",
      selector: "name",
      width: "220px",
    },
    {
      name: "chemin",
      selector: "path",
      width: "220px",
    },
    {
      name: "date de création",
      selector: "created_at",
      width: "220px",
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.created_at}</Moment>
        </div>
      ),
    },
    {
      name: "Date de suppression",
      selector: "deleted_at",
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.deleted_at}</Moment>
        </div>
      ),
    },

    {
      name: "Créé par ",
      sortable: true,
      width: "220px",
      cell: (row) => (
        <div>
          {row.created_by_lastname +
            " " +
            row.created_by_middlename +
            " " +
            row.created_by_firstname}
        </div>
      ),
    },
    {
      name: "Supprimé par ",
      sortable: true,
      width: "220px",
      cell: (row) => (
        <div>
          {row.deleted_by_lastname +
            " " +
            row.deleted_by_middlename +
            " " +
            row.deleted_by_firstname}
        </div>
      ),
    },

    {
      name: "Action",
      width: "130px",
      cell: (row) => (
        <div className="d-flex">
          {checkPermissions.includes("restaurer un document supprimé") && (
            <button
              className="mx-1 btn btn-primary"
              onClick={(e) => restore(row)}
            >
              Restaurer
            </button>
          )}
        </div>
      ),
    },
  ];

  const restore = (row) => {
    API.patch(`/restore-deleted-documents/${row.id}`).then((res) => {
      Swal.fire({
        title: "Restauration du document",
        type: "success",
        text: "Document restauré avec succès !",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });

      getData();
    });
  };

  return (
    <React.Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Corbeille</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste des documents supprimés
                  </li>
                </ol>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div className="col-4 mb-3">
                  <input
                    type="text"
                    className="form-control border-end-0 border rounded-pill"
                    placeholder="Recherche..."
                    onChange={handleSearch}
                  />
                </div>
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination={true}
                        progressPending={isLoading}
                        progressComponent={<Loader />}
                        fixedHeader={true}
                        striped
                        highlightOnHover
                        pointerOnHover
                        customStyles={customStyles}
                        noDataComponent={
                          <div className="alert alert-warning">
                            Il n'y a pas d'enregistrements à afficher
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentDeleted;
