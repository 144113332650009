import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../API/Network";

const Header = () => {
  const [myProfile, setMyProfile] = useState([]);

  useEffect(() => {
    API.get("/my-profile")
      .then((response) => {
        setMyProfile(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleLogout = () => {
    API.get("/logout")
      .then((response) => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("permissions");

        window.location.href = "/";
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <React.Fragment>
      <div class="main-header side-header sticky">
        <div class="main-container container-fluid">
          <div class="main-header-left">
            <a class="main-header-menu-icon" href="#" id="mainSidebarToggle">
              <span></span>
            </a>
            <div class="hor-logo">
              <a class="main-logo" href="#">
                <img
                  src="/assets/img/brand/logo.png"
                  class="header-brand-img desktop-logo"
                  alt="logo"
                />
                <img
                  src="/assets/img/brand/logo-light.png"
                  class="header-brand-img desktop-logo-dark"
                  alt="logo"
                />
              </a>
            </div>
          </div>

          <div class="main-header-right">
            <button
              class="navbar-toggler navresponsive-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent-4"
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </button>
            <div class="navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
              <div
                class="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div class="d-flex order-lg-2 ms-auto">
                  <div class="dropdown ">
                    <a class="nav-link icon full-screen-link">
                      <i class="fe fe-maximize fullscreen-button fullscreen header-icons"></i>
                      <i class="fe fe-minimize fullscreen-button exit-fullscreen header-icons"></i>
                    </a>
                  </div>
                  <div class="dropdown main-profile-menu">
                    <a class="d-flex" href="#">
                      <span class="main-img-user">
                        <img alt="avatar" src="/assets/img/users/1.jpg" />
                      </span>
                    </a>
                    <div className="dropdown-menu">
                      <div className="header-navheading">
                        <h6 className="main-notification-title">
                          {myProfile.contact &&
                            myProfile.contact.firstname +
                              " " +
                              myProfile.contact.lastname}
                        </h6>
                        <p className="main-notification-text">
                          {myProfile.roles &&
                            myProfile.roles.map((role) => role.name)}
                        </p>
                      </div>
                      <Link to="/profile" className="dropdown-item border-top">
                        <i className="fe fe-user"></i> Mon compte
                      </Link>
                      <button onClick={handleLogout} className="dropdown-item">
                        <i className="fe fe-power"></i> Se déconnecter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
