import React, { useState, useEffect } from "react";
import Signature from "./Signature";
import API from "../../API/Network";
import CreateSignature from "./Signature/CreateSignature";
import MyInfo from "./MyInfo";
import { Link, useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import Swal from "sweetalert2";

const Profile = () => {
  const [data, setData] = useState({});
  const [name, setName] = useState("");
  const [prefix, setPrefix] = useState({});
  const [myInfo, setMyInfo] = useState({});
  const [errors, setErrors] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmitPrefix = (event) => {
    event.preventDefault();

    API.post("/prefix-refs", {
      name: name,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Préfix créé avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        getData();
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };
  const deletePrefix = (id) => {
    API.delete(`/prefix-refs/${id}`).then((res) => {
      Swal.fire({
        title: "Suppression",
        type: "error",
        text: "Préfix supprimée !",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });

      getData();
    });
  };
  const getData = () => {
    API.get("/user-signature")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/my-profile")
      .then((res) => {
        setMyInfo(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/prefix-refs")
      .then((res) => {
        setPrefix(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div class="inner-body">
            <div class="page-header">
              <div>
                <h2 class="main-content-title tx-24 mg-b-5">Profile</h2>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Dashoard</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Profile
                  </li>
                </ol>
              </div>
            </div>
            <div class="row square">
              <div class="col-lg-12 col-md-12">
                <div class="card custom-card">
                  <div class="card-body">
                    <div class="panel profile-cover">
                      <div class="profile-cover__img">
                        <img src="../assets/img/users/1.jpg" alt="img" />
                        <h3 class="h3">
                          {myInfo.contact &&
                            myInfo.contact.firstname +
                              " " +
                              myInfo.contact.lastname}
                        </h3>
                      </div>
                      <div class="profile-cover__action bg-img"></div>
                    </div>
                    <div
                      class="profile-tab tab-menu-heading"
                      style={{ marginTop: "85px" }}
                    >
                      <nav class="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                        <a
                          class="nav-link  active"
                          data-bs-toggle="tab"
                          href="#about"
                        >
                          Mes infos
                        </a>
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#signature"
                        >
                          {data.image
                            ? "Voir ma signature"
                            : "Uploader la signature"}
                        </a>

                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#prefixRef"
                        >
                          Préfix de référence
                        </a>
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#changePassword"
                        >
                          Changer le mot de passe
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-sm">
              <div class="col-lg-12 col-md-12">
                <div class="card custom-card main-content-body-profile">
                  <div class="tab-content">
                    <div
                      class="main-content-body tab-pane p-4 border-top-0 active"
                      id="about"
                    >
                      <MyInfo myInfo={myInfo} />
                    </div>
                    <div
                      class="main-content-body p-4 border tab-pane border-top-0"
                      id="signature"
                    >
                      {data.image ? (
                        <Signature image={data.image} />
                      ) : (
                        <CreateSignature />
                      )}
                    </div>
                    <div
                      class="main-content-body p-4 border tab-pane border-top-0"
                      id="prefixRef"
                    >
                      {prefix.name ? (
                        <div class="card-body border">
                          <div class="col-sm-6 col-lg-4  offset-4">
                            <p className="text-muted">
                              Mon Préfix :{" "}
                              <span className="fw-bold mx-2">
                                {" "}
                                {prefix.name}
                              </span>{" "}
                            </p>
                            <Link
                              to={`/edit-prefix/${prefix.id}`}
                              className="btn btn-primary mx-2"
                            >
                              Editer
                            </Link>

                            <button
                              onClick={(e) => deletePrefix(prefix.id)}
                              className="btn btn-danger"
                            >
                              Supprimer
                            </button>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={handleSubmitPrefix}>
                          <div className="text-center">
                            <div className="form-group col-md-4 offset-md-4">
                              <input
                                onChange={handleNameChange}
                                type="text"
                                placeholder="Veuillez configurer votre préfix"
                                class={`form-control ${
                                  errors && errors.name ? "is-invalid" : ""
                                }`}
                              />
                              {errors && errors.name ? (
                                <div className="text-danger">
                                  {errors["name"]}{" "}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="text-start mt-1 ms-0">
                                {errors && errors === "ref_exists" ? (
                                  <div className="alert alert-warning">
                                    Vous avez déjà un préfix de référence{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <p classNameName="text-center">
                              <button className="text-center btn btn-primary">
                                Enregistrer
                              </button>
                            </p>
                          </div>
                        </form>
                      )}
                    </div>
                    <div
                      class="main-content-body tab-pane p-4 border-top-0"
                      id="changePassword"
                    >
                      <ChangePassword />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
