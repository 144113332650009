import React, { useState, useEffect } from "react";
import Home from "../../Home";
import { useNavigate, Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";
import Select from "react-select";

const AffectationCourrier = () => {
  const [users, setUsers] = useState({});
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const options = [
    {
      value: users.id,
      label: users.lastname + " " + users.middlename + " " + users.firstname,
    },
  ];

  useEffect(() => {
    API.get(`/get-destinataire-info/${id}`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((errors) => {
        console.log.log(errors.message);
      });

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    API.post(`/affectation/${id}`)
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "courrier affecté avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };
  if (redirect) {
    navigate(-1);
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <Home />
        <div className="main-content side-content pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Affecter le courrier à un utilisateur
                        </h6>
                        <Link
                          to="/courriers"
                          className="text-muted card-sub-title text-primary"
                        >
                          Voir la liste des courriers{" "}
                        </Link>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="text-center">
                          <div className="form-group col-md-4 offset-md-4">
                            <Select
                              options={options}
                              value={options}
                              loadingMessage="Chargement en cours..."
                              isDisabled={true}
                            />
                          </div>
                          <p classNameName="text-center">
                            <button className="text-center btn btn-primary">
                              Affecter
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default AffectationCourrier;
