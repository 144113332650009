import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import API from "../../../API/Network";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";
import { PermissionContext } from "../../../Services/PermissionContext";
import customStyles from "../../../utils/datatables/customStyles";
import { Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { baseURL } from "../../../Config/env";

const Document = () => {
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { id } = useParams();
  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const getData = () => {
    API.get(`/folders/${id}/docs`)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    console.log(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.created_at.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },

    {
      name: "Créé par",
      width: "200px",
      cell: (row) => (
        <div>{row.lastname + " " + row.middlename + " " + row.firstname}</div>
      ),
    },
    {
      name: "Date de Création",
      selector: "created_at",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.created_at}</Moment>
        </div>
      ),
    },
    {
      name: "Date de modification",
      selector: "updated_at",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.updated_at}</Moment>
        </div>
      ),
    },

    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <div className="d-flex">
          {checkPermissions.includes("lire un document") && (
            <Link to={`/document/${row.id}`} className="mx-2 btn btn-info">
              Voir
            </Link>
          )}
          {checkPermissions.includes("supprimer un document") && (
            <button className="btn btn-danger" onClick={(e) => deleteDoc(row)}>
              Supprimer
            </button>
          )}
        </div>
      ),
    },
  ];

  const deleteDoc = (row) => {
    API.delete(`/documents/${row.id}`)
      .then((res) => {
        Swal.fire({
          title: "Suppression",
          type: "success",
          text: "document supprimé !",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();

    bodyFormData.set("name", name);
    bodyFormData.set("path", selectedFile);
    bodyFormData.set("folder_id", id);

    let headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(`${baseURL}/api/ebutelo/documents`, bodyFormData, headers)
      .then((res) => {
        handleClose();
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Document créé avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Document</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dasboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dossier
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste des documents
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  {checkPermissions.includes("scanner un document") && (
                    <p className=" text-end">
                      <Button variant="primary" onClick={handleShow}>
                        Scanner un document
                      </Button>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div class="row row-sm">
              <div class="col-lg-12">
                <div className="col-4 mb-3">
                  <input
                    type="text"
                    className="form-control border-end-0 border rounded-pill"
                    placeholder="Recherche..."
                    onChange={handleSearch}
                  />
                </div>
                <div class="card custom-card overflow-hidden">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination={true}
                          progressPending={isLoading}
                          progressComponent={<Loader />}
                          fixedHeader={true}
                          striped
                          highlightOnHover
                          pointerOnHover
                          customStyles={customStyles}
                          noDataComponent={
                            <div className="alert alert-warning">
                              Il n'y a pas d'enregistrements à afficher
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Scanner document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="le nom du fichier..."
              onChange={handleNameChange}
              className={`form-control ${
                errors && errors.name ? "is-invalid" : ""
              }`}
            />
            {errors && errors.name ? (
              <div className="text-danger">{errors["name"]} </div>
            ) : (
              ""
            )}
            <Form.Group className="mt-3" controlId="formFile">
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button
              className="mt-3"
              variant="primary"
              type="submit"
              disabled={!selectedFile}
            >
              Uploader
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Document;
