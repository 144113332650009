import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../API/Network";
import Swal from "sweetalert2";
import Loader from "../../Loader";
import { PermissionContext } from "../../../Services/PermissionContext";
import DataTable from "react-data-table-component";
import customStyles from "../../../utils/datatables/customStyles";

const RedactionArchived = () => {
  const [redactions, setRedactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [prefixRef, setPrefixRef] = useState({});
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const getData = () => {
    API.get("/get-archive-redactions")
      .then((res) => {
        setRedactions(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/prefix-refs")
      .then((res) => {
        setPrefixRef(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = redactions.filter(
    (item) =>
      item.motif.toLowerCase().includes(searchText.toLowerCase()) ||
      item.reference.count_ref.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Numéro référence",
      selector: "count_ref",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          {prefixRef.name}/{row.reference.count_ref}
        </div>
      ),
    },
    {
      name: "Date réception",
      selector: "date_reception",
      sortable: true,
      cell: (row) => <div>{row.date_reception}</div>,
    },

    {
      name: "Motif",
      selector: "motif",
      sortable: true,
      width: "300px",
    },

    {
      name: "expediteur",
      selector: "expediteur",
      sortable: true,
      width: "300px",
      cell: (row) => <div>{row.expediteur.contact.full_name}</div>,
    },
    {
      name: "Destinataire",
      selector: "destinataire",
      sortable: true,
      width: "300px",
    },

    {
      name: "Action",
      auto: true,
      width: "250px",

      cell: (row) => (
        <div className="d-flex">
          {checkPermissions.includes("restaurer un courrier archivé") &&
            row.isArchived === 1 && (
              <button
                className="mx-1 btn btn-primary"
                onClick={(e) => restoreRedaction(row)}
              >
                Restaurer
              </button>
            )}
          <Link to={`/redaction/${row.id}`} className="btn btn-info">
            Afficher
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const restoreRedaction = (row) => {
    API.get(`/restore-redaction/${row.id}`)
      .then((res) => {
        Swal.fire({
          title: "Archivé !",
          type: "success",
          text: "Redaction restaurée avec succès !",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteRedaction = (row) => {
    API.delete(`/redactions/${row.id}`).then((res) => {
      Swal.fire({
        title: "Suppression",
        type: "success",
        text: "Rédaction supprimée !",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });

      getData();
    });
  };

  return (
    <React.Fragment>
      <div className="table-responsive">
        <div className="col-4 mb-3">
          <input
            type="text"
            className="form-control border-end-0 border rounded-pill"
            placeholder="Recherche..."
            onChange={handleSearch}
          />
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination={true}
          progressPending={isLoading}
          progressComponent={<Loader />}
          fixedHeader={true}
          striped
          highlightOnHover
          pointerOnHover
          responsive
          customStyles={customStyles}
          noDataComponent={
            <div className="alert alert-warning">
              Il n'y a pas d'enregistrements à afficher
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default RedactionArchived;
