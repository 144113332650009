import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import API from "../../API/Network";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../Config/env";

// Create Document Component
const PrintAnswer = () => {
  const [data, setData] = useState([]);
  const [setting, setSetting] = useState([]);
  const [prefixRef, setPrefixRef] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    API.get("/settings")
      .then((response) => {
        setSetting(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    getData();
  }, []);

  const getData = () => {
    let id = params.id;
    API.get(`/answer/${id}`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/prefix-refs")
      .then((res) => {
        setPrefixRef(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "#000",
      fontSize: "13px",
    },
    logo: {
      width: 90,
      height: 80,
      marginLeft: "0",
    },
    signature: {
      width: 90,
      left: "400",
      height: 80,
    },
    expediteurName: {
      marginTop: "40",
      left: "350",
    },
    concerne: {
      marginTop: "20px",
      marginBottom: "50px",
    },
    content: {
      fontSize: 11,
      textAlign: "justify",
      fontFamily: "Times New Roman",
    },
    date: {
      position: "absolute",
      top: 55,
      right: 0,
    },
    head: {
      position: "absolute",
      top: 120,
      right: 0,
    },

    place: {
      position: "absolute",
      top: 150,
      right: 20,
      textDecoration: "underline",
      fontWeight: "bold",
    },

    section: {
      margin: 10,
      padding: 10,
      fontSize: "11px",
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
      fontFamily: "Times New Roman",
    },
  });

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Image
              src={`${baseURL}/storage/settings/${setting.logo}`}
              alt="Logo"
              style={styles.logo}
            />
            <Text>
              N/Réf. : {prefixRef.name}/
              {data.reference && data.reference.count_ref}
            </Text>
            <Text style={styles.date}>
              {setting.city}, le {data.created_at}
            </Text>
            {data.courrier && (
              <React.Fragment>
                <Text style={styles.head}>
                  {data.courrier.civility === "Madame"
                    ? "A Madame la"
                    : "A Monsieur le"}{" "}
                  {data.courrier.occupation} {setting.name} {", "}
                </Text>
                <Text style={styles.place}>
                  {"A "}
                  {setting.town}/{setting.city}
                </Text>
                <Text style={styles.concerne}>Concerne. : {data.concerne}</Text>
                <Html style={styles.content}>{data.content}</Html>

                <Text style={styles.expediteurName}>
                  {data.courrier.user.contact.full_name}
                </Text>
              </React.Fragment>
            )}
            {data.signature && (
              <Image
                src={`${baseURL}/storage/signatures/${data.signature.image}`}
                alt="Logo"
                style={styles.signature}
              />
            )}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default PrintAnswer;
