import React, { useState } from "react";
import axios from "axios";
import { baseURL, baseURl } from "../../Config/env";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState([]);

  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  const passwordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.set("email", email);
    bodyFormData.set("password", password);

    axios
      .post(`${baseURL}/api/ebutelo/login`, bodyFormData)
      .then((res) => {
        console.log(res.data.user);
        localStorage.setItem("token", res.data.token);
        res.data.user.roles.map((role) =>
          localStorage.setItem("role", role.name)
        );

        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );

        setRedirect(true);
      })
      .catch((error) => {
        if (error) {
          setErrors(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    //return navigate("/dashboard");
    window.location.href = "/dashboard";
  }
  return (
    <div className="page main-signin-wrapper">
      <div className="row signpages text-center">
        <div className="col-md-12">
          <div className="card">
            <div className="row row-sm">
              <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                <div className="mt-5 pt-4 p-2 pos-absolute">
                  <img
                    src="../assets/img/brand/logo-light.png"
                    className="d-lg-none header-brand-img text-start float-start mb-4 error-logo-light"
                    alt="logo"
                  />
                  <img
                    src="../assets/img/brand/logo.png"
                    className=" d-lg-none header-brand-img text-start float-start mb-4 error-logo"
                    alt="logo"
                  />
                  <div className="clearfix"></div>
                  <img
                    src="../assets/img/brand/LOGO1.png"
                    className="ht-100 mb-0"
                    alt="user"
                  />
                  {/* <h5 className="mt-4 text-white">Bienvue à Ebutelo👋</h5> */}
                  <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                    Un logiciel à l'échelle de vos besoins
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                <div className="main-container container-fluid">
                  <div className="row row-sm">
                    <div className="card-body mt-2 mb-2">
                      <img
                        src="../assets/img/brand/logo.png"
                        className=" d-lg-none header-brand-img text-start float-start mb-4"
                        alt="logo"
                      />
                      <div className="clearfix"></div>
                      <form onSubmit={handleSubmit}>
                        <h5 className="text-start mb-2">Connexion</h5>
                        <div className="form-group text-start">
                          <label>Email</label>
                          <input
                            type="email"
                            className={`form-control ${
                              errors && errors.email ? "is-invalid" : ""
                            }`}
                            onChange={emailChange}
                          />
                          {errors && errors.email ? (
                            <div className="text-danger">
                              {errors["email"]}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group text-start">
                          <label>Password</label>
                          <input
                            name="password"
                            type="password"
                            id="login-password"
                            className={`form-control ${
                              errors && errors.password ? "is-invalid" : ""
                            }`}
                            onChange={passwordChange}
                          />
                          {errors && errors.password ? (
                            <div className="text-danger">
                              {errors["password"]}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <button className="btn ripple btn-main-primary btn-block mt-2">
                          Se connecter
                        </button>
                      </form>
                      <div className="text-start mt-5 ms-0">
                        {errors && errors === "bad_credentials" ? (
                          <div className="alert alert-warning">
                            Vos identifiants de connexion sont incorrects{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
