import React from "react";

const permissions = JSON.parse(localStorage.getItem("permissions"));
 let  arrayPermissions = [];

if(permissions){
  arrayPermissions = permissions.map((permission) =>(
    permission.name
  ))
}

const PermissionContext = React.createContext(arrayPermissions)


export {PermissionContext}


