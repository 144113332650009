import React, { useState, useEffect, useContext } from "react";
import API from "../../../API/Network";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { PermissionContext } from "../../../Services/PermissionContext";
import DataTable from "react-data-table-component";
import customStyles from "../../../utils/datatables/customStyles";
import { baseURL } from "../../../Config/env";
import RedactionListDeleted from "./RedactionListDeleted";

const CourrierCorbeille = () => {
  const [courriers, setCourriers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const getData = () => {
    API.get("/get-deleted-courriers")
      .then((res) => {
        setCourriers(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = courriers.filter(
    (item) =>
      item.motif.toLowerCase().includes(searchText.toLowerCase()) ||
      item.numero_accuse.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Numéro accusé",
      selector: "numero_accuse",
      sortable: true,
    },
    {
      name: "Date réception",
      selector: "date_reception",
      sortable: true,
      cell: (row) => <div>{row.date_reception}</div>,
    },
    {
      name: "Date de suppression",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.date_archive}</Moment>
        </div>
      ),
    },
    {
      name: "Observation",
      selector: "observation",
      sortable: true,
    },
    {
      name: "Motif",
      selector: "motif",
      sortable: true,
    },
    {
      name: "expediteur",
      selector: "expediteur",
      sortable: true,
      width: "200px",
    },
    {
      name: "Fichier",
      selector: "doc",
      sortable: true,
      width: "300px",

      cell: (row) => (
        <a
          href={`${baseURL}/storage/docs/${row.doc}`}
          target="_blank"
          title="Cliquer pour voir le courrier"
          rel="noopener noreferrer"
        >
          {row.doc}
        </a>
      ),
    },
    {
      name: "Destinataire",
      selector: "destinataire",
      sortable: true,
      width: "300px",
      cell: (row) => (
        <div>
          {row.destinataire_lastname} {row.destinataire_middlename}{" "}
          {row.destinataire_firstname}
        </div>
      ),
    },

    {
      name: "Action",
      auto: true,
      width: "150px",

      cell: (row) => (
        <div className="d-flex">
          {checkPermissions.includes("restaurer un courrier supprimé") && (
            <button
              className="mx-1 btn btn-primary"
              onClick={(e) => restore(row)}
            >
              Restaurer
            </button>
          )}
        </div>
      ),
    },
  ];

  const restore = (row) => {
    API.patch(`/restore-deleted-courrier/${row.id}`)
      .then((res) => {
        Swal.fire({
          title: "Restauration courrier",
          type: "success",
          text: "courrier restauré avec succès !",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Corbeille</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste des courriers supprimés
                  </li>
                </ol>
              </div>
            </div>
            <div class="row row-sm">
              <div class="col-lg-12">
                <div class="card custom-card mg-b-20" id="tabs-style2">
                  <div class="card-body">
                    <div class="text-wrap">
                      <div class="example">
                        <div class="panel panel-primary tabs-style-2">
                          <div class=" tab-menu-heading">
                            <div class="tabs-menu1">
                              <ul class="nav panel-tabs main-nav-line">
                                <li>
                                  <a
                                    href="#courrier"
                                    class="nav-link active mt-1"
                                    data-bs-toggle="tab"
                                  >
                                    Courriers
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#redaction"
                                    class="nav-link mt-1"
                                    data-bs-toggle="tab"
                                  >
                                    Rédactions
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="panel-body tabs-menu-body main-content-body-right border">
                            <div class="tab-content">
                              <div class="tab-pane active" id="courrier">
                                <div className="col-4 mb-3">
                                  <input
                                    type="text"
                                    className="form-control border-end-0 border rounded-pill"
                                    placeholder="Recherche..."
                                    onChange={handleSearch}
                                  />
                                </div>
                                <div className="table-responsive">
                                  <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination={true}
                                    progressPending={isLoading}
                                    progressComponent={<Loader />}
                                    fixedHeader={true}
                                    striped
                                    highlightOnHover
                                    pointerOnHover
                                    responsive
                                    customStyles={customStyles}
                                    noDataComponent={
                                      <div className="alert alert-warning">
                                        Il n'y a pas d'enregistrements à
                                        afficher
                                      </div>
                                    }
                                  />
                                </div>
                              </div>
                              <div class="tab-pane" id="redaction">
                                <RedactionListDeleted />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourrierCorbeille;
