import React, { useState, useEffect, useContext } from "react";
import API from "../../API/Network";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Loader from "../Loader";
import { PermissionContext } from "../../Services/PermissionContext";
import DataTable from "react-data-table-component";
import customStyles from "../../utils/datatables/customStyles";

const OutAnswer = () => {
  const [outAnswer, setOutAnswer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [prefixRef, setPrefixRef] = useState({});
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const permissions = useContext(PermissionContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }

    API.get("/prefix-refs")
      .then((res) => {
        setPrefixRef(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/out-answer")
      .then((res) => {
        setOutAnswer(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = outAnswer.filter(
    (item) =>
      item.concerne.toLowerCase().includes(searchText.toLowerCase()) ||
      item.reference.count_ref.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Numéro référence",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          {prefixRef.name}/{row.reference.count_ref}
        </div>
      ),
    },
    {
      name: "Date réception",
      selector: "date_reception",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.date_reception}</Moment>
        </div>
      ),
    },

    {
      name: "Concerne",
      selector: "concerne",
      sortable: true,
      width: "300px",
    },
    {
      name: "Expéditeur",
      sortable: true,
      width: "300px",
      cell: (row) => <div>{row.courrier.user.contact.full_name}</div>,
    },

    {
      name: "Destinataire",
      sortable: true,
      width: "300px",
      cell: (row) => <div>{row.courrier?.expediteur}</div>,
    },

    {
      name: "Action",
      width: "120px",
      cell: (row) => (
        <div className="d-flex">
          {permissions.includes("imprimer un courier sortant") && (
            <Link to={`/print-answer/${row.id}`} className="btn btn-primary">
              Imprimer
            </Link>
          )}
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="table-responsive">
        <div className="col-4 mb-3">
          <input
            type="text"
            className="form-control border-end-0 border rounded-pill"
            placeholder="Recherche..."
            onChange={handleSearch}
          />
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination={true}
          progressPending={isLoading}
          progressComponent={<Loader />}
          fixedHeader={true}
          striped
          highlightOnHover
          pointerOnHover
          responsive
          customStyles={customStyles}
          noDataComponent={
            <div className="alert alert-warning">
              Il n'y a pas d'enregistrements à afficher
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default OutAnswer;
