import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import API from "../../API/Network";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { PermissionContext } from "../../Services/PermissionContext";
import customStyles from "../../utils/datatables/customStyles";

const LogHistory = () => {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  const getData = () => {
    API.get("/activity-logs")
      .then((res) => {
        setLogs(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = logs.filter((item) =>
    item.created_at.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getData();
  }, []);

  const columns = [
    {
      name: "Date & heure",
      selector: "created_at",
      sortable: true,
      cell: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.created_at}</Moment> à{" "}
          <Moment format="HH:mm">{row.created_at}</Moment>
        </div>
      ),
    },
    {
      name: "Evénement",
      sortable: true,
      cell: (row) => <div>{row.description}</div>,
    },

    {
      name: "Utilisateur",
      sortable: true,
      cell: (row) => (
        <div>
          {row.causer?.contact.lastname +
            " " +
            row.causer?.contact.middlename +
            " " +
            row.causer?.contact.firstname}
        </div>
      ),
    },

    {
      name: "Rôle",
      sortable: true,
      cell: (row) => <div>{row.causer?.roles.map((role) => role.name)}</div>,
    },
  ];

  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Historique des logs
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dasboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    liste des historiques
                  </li>
                </ol>
              </div>
            </div>
            <div class="row row-sm">
              <div class="col-lg-12">
                <div className="col-4 mb-3">
                  <input
                    type="text"
                    className="form-control border-end-0 border rounded-pill"
                    placeholder="Recherche..."
                    onChange={handleSearch}
                  />
                </div>
                <div class="card custom-card overflow-hidden">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination={true}
                          progressPending={isLoading}
                          progressComponent={<Loader />}
                          fixedHeader={true}
                          striped
                          highlightOnHover
                          pointerOnHover
                          customStyles={customStyles}
                          noDataComponent={
                            <div className="alert alert-warning">
                              Il n'y a pas d'enregistrements à afficher
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogHistory;
