import React, { useEffect, useState } from "react";
import API from "../../../API/Network";
import Loader from "./../../Loader/index";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import customStyles from "../../../utils/datatables/customStyles";

const RedactionReceived = () => {
  const [redactions, setRedactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [setting, setSetting] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = redactions.filter(
    (item) =>
      item.motif.toLowerCase().includes(searchText.toLowerCase()) ||
      item.count_ref.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Numéro accusé",
      selector: "numero_accuse",
      sortable: true,

      cell: (row) => (
        <div>
          {" "}
          {setting.global_ref}
          {setting.global_ref && <span>/</span>}
          {row.count_ref}
        </div>
      ),
    },
    {
      name: "Date réception",
      selector: "date_reception",
      sortable: true,
      cell: (row) => <div>{row.date_reception}</div>,
    },
    {
      name: "Motif",
      selector: "motif",
      sortable: true,
    },
    {
      name: "expediteur",
      selector: "expediteur",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          {row.lastname} {row.middlename} {row.firstname}
        </div>
      ),
    },
    {
      name: "Action",
      auto: true,

      cell: (row) => (
        <div className="d-flex">
          <Link
            to={`/preview-private-redaction/${row.id}`}
            className="btn btn-primary"
          >
            Afficher
          </Link>
        </div>
      ),
    },
  ];

  const getData = () => {
    API.get("/get-redactions-private-received")
      .then((res) => {
        setRedactions(res.data);
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/settings")
      .then((res) => {
        setSetting(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <React.Fragment>
      <div className="table-responsive">
        <div className="col-4 mb-3">
          <input
            type="text"
            className="form-control border-end-0 border rounded-pill"
            placeholder="Recherche..."
            onChange={handleSearch}
          />
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination={true}
          progressPending={isLoading}
          progressComponent={<Loader />}
          fixedHeader={true}
          striped
          highlightOnHover
          pointerOnHover
          responsive
          customStyles={customStyles}
          noDataComponent={
            <div className="alert alert-warning">
              Il n'y a pas d'enregistrements à afficher
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default RedactionReceived;
