import React, { useState, useEffect } from "react";
import Home from "../../Home";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";
import axios from "axios";
import { baseURL } from "../../../Config/env";

const EditSetting = () => {
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [idnat, setIdnat] = useState("");
  const [rccm, setRccm] = useState("");
  const [website, setWebsite] = useState("");
  const [globalRef, setGlobalRef] = useState("");
  const [numImpot, setNumImpot] = useState("");
  const [errors, setErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleIdnatChange = (event) => {
    setIdnat(event.target.value);
  };

  const handleRccmChange = (event) => {
    setRccm(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleTownChange = (event) => {
    setTown(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleGlobalRefChange = (event) => {
    setGlobalRef(event.target.value);
  };

  const handleNUmImpotChange = (event) => {
    setNumImpot(event.target.value);
  };

  const handleLogoChange = (event) => {
    setLogo(event.target.files[0]);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    document.title = "Configuration de base";
    if (!token) {
      navigate("/");
    }

    API.get(`/settings/${id}`).then((response) => {
      let oldData = response.data;
      setName(oldData.name);
      setAddress(oldData.address);
      setTown(oldData.town);
      setCity(oldData.city);
      setEmail(oldData.email);
      setPhone(oldData.phone);
      setIdnat(oldData.idnat);
      setRccm(oldData.rccm);
      setNumImpot(oldData.n_impot);
      setWebsite(oldData.web_site);
      setGlobalRef(oldData.global_ref);
    });
  }, []);

  const handleSumit = (e) => {
    e.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PATCH");

    bodyFormData.append("name", name);
    bodyFormData.append("address", address);
    bodyFormData.append("town", town);
    bodyFormData.append("city", city);
    bodyFormData.append("email", email);
    bodyFormData.append("phone", phone);
    bodyFormData.append("idnat", idnat);
    bodyFormData.append("rccm", rccm);
    bodyFormData.append("n_impot", numImpot);
    bodyFormData.append("web_site", website);
    bodyFormData.append("global_ref", globalRef);

    if (logo !== null) {
      bodyFormData.append("logo", logo);
    }

    let headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(`${baseURL}/api/ebutelo/settings/${id}`, bodyFormData, headers)
      .then(({ data }) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Configuration de base enregistrée avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate("/settings");
  }

  return (
    <React.Fragment>
      <Home />
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-card">
              <div class="card-body">
                <form
                  class="form-group"
                  onSubmit={handleSumit}
                  enctype="multipart/form-data"
                >
                  <div class="row row-sm">
                    <div class="col-lg-12 col-md-12">
                      <div class="card custom-card">
                        <div class="card-body">
                          <div class="row row-sm">
                            <div class="col-md-12 col-lg-12 col-xl-12">
                              <div class="">
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Nom</label>
                                      <input
                                        value={name}
                                        onChange={handleNameChange}
                                        class={`form-control ${
                                          errors && errors.name
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Entrez le nom de l'entreprise"
                                        type="text"
                                      />
                                      {errors && errors.name ? (
                                        <div className="text-danger">
                                          {errors["name"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4">
                                      <label class="">Email</label>
                                      <input
                                        value={email}
                                        onChange={handleEmailChange}
                                        class={`form-control ${
                                          errors && errors.email
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="L'email de l'entreprise"
                                        type="email"
                                      />
                                      {errors && errors.email ? (
                                        <div className="text-danger">
                                          {errors["email"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4">
                                      <label class="">Téléphone</label>
                                      <input
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        class={`form-control ${
                                          errors && errors.phone
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Le numére de téléphone"
                                        type="tel"
                                      />
                                      {errors && errors.phone ? (
                                        <div className="text-danger">
                                          {errors["phone"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <div class="col-sm-4">
                                      <label class="">Adresse</label>
                                      <input
                                        value={address}
                                        onChange={handleAddressChange}
                                        class={`form-control ${
                                          errors && errors.address
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Votre avenue et numéro de parcelle"
                                        type="text"
                                      />
                                      {errors && errors.address ? (
                                        <div className="text-danger">
                                          {errors["address"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Commune</label>
                                      <input
                                        value={town}
                                        onChange={handleTownChange}
                                        class={`form-control ${
                                          errors && errors.town
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Votre commune"
                                        type="text"
                                      />
                                      {errors && errors.town ? (
                                        <div className="text-danger">
                                          {errors["town"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Ville</label>
                                      <input
                                        value={city}
                                        onChange={handleCityChange}
                                        class={`form-control ${
                                          errors && errors.city
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Votre ville"
                                        type="text"
                                      />
                                      {errors && errors.city ? (
                                        <div className="text-danger">
                                          {errors["city"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <div class="col-sm-4">
                                      <label class="">
                                        Numéro d'impôt{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        value={numImpot}
                                        onChange={handleNUmImpotChange}
                                        class={`form-control ${
                                          errors && errors.n_impot
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="le numéro d'impôt"
                                        type="text"
                                      />
                                      {errors && errors.n_impot ? (
                                        <div className="text-danger">
                                          {errors["n_impot"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        RCCM{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        value={rccm}
                                        onChange={handleRccmChange}
                                        class={`form-control ${
                                          errors && errors.rccm
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="RCCM"
                                        type="text"
                                      />
                                      {errors && errors.rccm ? (
                                        <div className="text-danger">
                                          {errors["rccm"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        IDNAT{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        value={idnat}
                                        onChange={handleIdnatChange}
                                        class={`form-control ${
                                          errors && errors.idnat
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="IDNAT"
                                        type="text"
                                      />
                                      {errors && errors.idnat ? (
                                        <div className="text-danger">
                                          {errors["idnat"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        Site web{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>{" "}
                                      </label>
                                      <input
                                        value={website}
                                        onChange={handleWebsiteChange}
                                        class={`form-control ${
                                          errors && errors.web_site
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="ex: https://ebutelo.com"
                                        type="text"
                                      />
                                      {errors && errors.web_site ? (
                                        <div className="text-danger">
                                          {errors["web_site"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        Référence globale{" "}
                                        <span className="text-muted"></span>{" "}
                                      </label>
                                      <input
                                        value={globalRef}
                                        onChange={handleGlobalRefChange}
                                        class={`form-control ${
                                          errors && errors.global_ref
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="ex: ADN"
                                        type="text"
                                      />
                                      {errors && errors.global_ref ? (
                                        <div className="text-danger">
                                          {errors["global_ref"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Logo</label>
                                      <input
                                        onChange={handleLogoChange}
                                        class={`form-control ${
                                          errors && errors.logo
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        type="file"
                                      />
                                      {errors && errors.logo ? (
                                        <div className="text-danger">
                                          {errors["logo"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <button class="btn ripple btn-main-primary btn-block">
                                  Modifier
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditSetting;
