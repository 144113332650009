import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API from "../../../API/Network";
import Swal from "sweetalert2";

const EditFolder = () => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get(`/get-subfolder/${id}`).then((response) => {
      let docs = response.data;
      setName(docs.name);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.patch(`folders/${id}`, {
      name: name,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "sous dossier a été modifiée avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate("/folders");
  }

  return (
    <React.Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Dossiers</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Editer un dossier
                  </li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center"></div>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div className="card custom-card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="text-center">
                        <div className="col-4 offset-md-4 mb-3">
                          <input
                            value={name}
                            name="name"
                            onChange={handleNameChange}
                            className={`form-control ${
                              errors && errors.name ? "is-invalid" : ""
                            }`}
                            placeholder="Saisissez un nom"
                            type="text"
                          />
                          {errors && errors.name ? (
                            <div className="text-danger">{errors["name"]} </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <p classNameName="text-center">
                          <button className="text-center btn btn-primary">
                            Modifier
                          </button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditFolder;
