import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

const CreateRedaction = () => {
  const [motif, setMotif] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [destinataire, setDestinataire] = useState("");
  const [civilite, setCivilite] = useState("");
  const [occupation, setOccupation] = useState("");
  const [info, setInfo] = useState([""]);
  const [errors, setErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [destinataireType, setDestinataireType] = useState("externe");
  const [selected, setSelected] = useState("");
  const [users, setUsers] = useState([]);

  const [selectedDestinatairesInternes, setSelectedDestinatairesInternes] =
    useState([]);

  const navigate = useNavigate();
  const handleMotifChange = (event) => {
    setMotif(event.target.value);
  };

  const options = users.map((user) => ({
    label: user.lastname + " " + user.middlename + " " + user.firstname,
    value: user.id,
  }));

  const handleDestinataireInterneChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedDestinatairesInternes(selectedIds);

    console.log(selectedIds);
  };

  const handleInfoChange = (event, index) => {
    const newInfo = [...info];
    newInfo[index] = event.target.value;
    setInfo(newInfo);
  };

  const handleAddInfo = () => {
    if (info.length < 10) {
      setInfo([...info, ""]);
    }
  };

  function handleRemoveInfo(index) {
    const newInfo = [...info];
    newInfo.splice(index, 1);
    setInfo(newInfo);
  }

  const handleDestinataireChange = (event) => {
    setDestinataire(event.target.value);
  };

  const handleCiviliteChange = (event) => {
    setCivilite(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleDestinataireTypeChange = (event) => {
    setDestinataireType(event.target.value);
    setSelectedDestinatairesInternes([]);
  };

  const handleContentChange = (event, editor) => {
    setContent(editor.getData());
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const civilities = [
    { value: "Monsieur", label: "Monsieur" },
    { value: "Madame", label: "Madame" },
    { value: "Docteur", label: "Docteur" },
  ];

  useEffect(() => {
    API.get("/allUsers-without-auth")
      .then((res) => {
        setUsers(res.data);
        console.log(res.data);
      })
      .catch((errors) => {
        console.log.log(errors.message);
      });

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    API.post("/redactions", {
      motif: motif,
      destinataire: destinataire,
      destinataire_interne_id: selected,
      content: content,
      email: email,
      civility: civilite,
      occupation: occupation,
      destinataire_type: destinataireType,
      info: info.join(","),
      destinataires_internes: selectedDestinatairesInternes.join(","),
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Rédaction a bien été enregistrée",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate("/redactions");
  }

  return (
    <React.Fragment>
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-card">
              <div class="card-body">
                <form
                  class="form-group"
                  onSubmit={handleSubmit}
                  enctype="multipart/form-data"
                >
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                      <label class="mg-b-0">Concerne</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <input
                        onChange={handleMotifChange}
                        class={`form-control ${
                          errors && errors.motif ? "is-invalid" : ""
                        }`}
                        placeholder="Entrez le motif"
                        type="text"
                      />
                      {errors && errors.motif ? (
                        <div className="text-danger">{errors["motif"]} </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row row-xs align-items-center mg-b-20">
                    <div className="col-md-4">
                      <label className="mg-b-0">Type de destinataire</label>
                    </div>
                    <div className="col-md-8 mg-t-5 mg-md-t-0">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          id="interne"
                          type="radio"
                          name="destinataireType"
                          value="interne"
                          checked={destinataireType === "interne"}
                          onChange={handleDestinataireTypeChange}
                        />
                        <label className="form-check-label" htmlFor="interne">
                          Interne
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className=" form-check-input"
                          id="externe"
                          type="radio"
                          name="destinataireType"
                          value="externe"
                          checked={destinataireType === "externe"}
                          onChange={handleDestinataireTypeChange}
                        />
                        <label className=" form-check-label" htmlFor="externe">
                          Externe
                        </label>
                      </div>
                    </div>
                  </div>

                  {destinataireType === "externe" && (
                    <>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-4">
                          <label class="mg-b-0">Civilité</label>
                        </div>
                        <div class="col-md-8 mg-t-5 mg-md-t-0">
                          <select
                            className="form-control select select2"
                            onChange={handleCiviliteChange}
                          >
                            <option value="">Sélectionner une civilité</option>
                            {civilities.map((civility) => (
                              <option
                                key={civility.value}
                                value={civility.value}
                              >
                                {civility.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-4">
                          <label class="mg-b-0">Occupation</label>
                        </div>
                        <div class="col-md-8 mg-t-5 mg-md-t-0">
                          <input
                            onChange={handleOccupationChange}
                            class={`form-control ${
                              errors && errors.occupation ? "is-invalid" : ""
                            }`}
                            placeholder="le poste qu'occupe l'expéditeur"
                            type="text"
                          />
                          {errors && errors.occupation ? (
                            <div className="text-danger">
                              {errors["occupation"]}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-4">
                          <label class="mg-b-0">Destinataire</label>
                        </div>
                        <div class="col-md-8 mg-t-5 mg-md-t-0">
                          <input
                            onChange={handleDestinataireChange}
                            class={`form-control ${
                              errors && errors.destinataire ? "is-invalid" : ""
                            }`}
                            placeholder="Entrez le destinataire"
                            type="text"
                          />
                          {errors && errors.destinataire ? (
                            <div className="text-danger">
                              {errors["destinataire"]}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-4">
                          <label class="mg-b-0">
                            Email{" "}
                            <span className="text-muted"> (destinataire)</span>
                          </label>
                        </div>
                        <div class="col-md-8 mg-t-5 mg-md-t-0">
                          <input
                            onChange={handleEmailChange}
                            class={`form-control ${
                              errors && errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Entrez le email du destinataire"
                            type="email"
                          />
                          {errors && errors.email ? (
                            <div className="text-danger">
                              {errors["email"]}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {info.map((name, index) => (
                        <div
                          className="row row-xs align-items-center mg-b-20"
                          key={index}
                        >
                          <div class="col-md-4">
                            <label class="mg-b-0" htmlFor={`name-${index}`}>
                              Transmie copie {index + 1}
                            </label>
                          </div>
                          <div class="col-md-8 mg-t-5 mg-md-t-0 d-flex">
                            <input
                              type="text"
                              className="form-control mx-2"
                              id={`name-${index}`}
                              value={name}
                              onChange={(event) =>
                                handleInfoChange(event, index)
                              }
                            />
                            {index > 0 && (
                              <button
                                type="button"
                                className="fe fe-trash-2 btn btn-danger"
                                onClick={() => handleRemoveInfo(index)}
                              ></button>
                            )}
                          </div>
                        </div>
                      ))}
                      {info.length < 10 && (
                        <button
                          className="fe fe-plus btn btn-info mb-3"
                          type="button"
                          onClick={handleAddInfo}
                        >
                          Ajouter
                        </button>
                      )}
                    </>
                  )}
                  {destinataireType === "interne" && (
                    <>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-4">
                          <label class="mg-b-0">Destinataire principal</label>
                        </div>
                        <div class="col-md-8 mg-t-5 mg-md-t-0">
                          <select
                            className="form-control select select2"
                            onChange={(e) => setSelected(e.target.value)}
                          >
                            <option value="">
                              Sélectionner un destinatire
                            </option>
                            {users.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.lastname} {user.middlename}
                                {user.firstname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div class="row row-xs align-items-center mg-b-20 mb-5">
                        <div class="col-md-4">
                          <label class="mg-b-0">Copies pour informations</label>
                        </div>
                        <div class="col-md-8 mg-t-5 mg-md-t-0 ">
                          <Select
                            isMulti
                            options={options}
                            value={selectedDestinatairesInternes.map((id) => ({
                              value: id,
                              label: `${
                                users.find((user) => user.id === id).lastname
                              } ${
                                users.find((user) => user.id === id).firstname
                              }`,
                            }))}
                            onChange={handleDestinataireInterneChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      handleContentChange(event, editor);
                    }}
                  />
                  {errors && errors.content ? (
                    <div className="text-danger">{errors["content"]} </div>
                  ) : (
                    ""
                  )}
                  <div class="form-group row justify-content-end mb-0">
                    <div class="col-md-8 ps-md-2 mt-3">
                      <button class="btn ripple btn-primary pd-x-30 mg-r-5">
                        Enregister
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateRedaction;
