import React, { useState, useEffect, useContext } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Home from "../Home";
import { useParams } from "react-router-dom";
import API from "../../API/Network";
import Answer from "./Answer";
import DisplayAnswer from "./DisplayAnswer";
import { PermissionContext } from "../../Services/PermissionContext";
import { baseURL } from "../../Config/env";

const MailAffectedToRedaction = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const checkPermissions = useContext(PermissionContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get(`/mail-a-traiter/previewPdf/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const url = `${baseURL}/storage/docs/${data.doc}`;

  return (
    <React.Fragment>
      <Home />
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div className="card custom-card mg-b-20" id="tabs-style2">
            <div className="card-body">
              <div className="text-wrap">
                <div className="example">
                  <div className="panel panel-primary tabs-style-2">
                    <div className=" tab-menu-heading">
                      <div className="tabs-menu1">
                        <ul className="nav panel-tabs main-nav-line">
                          <li>
                            <a
                              href="#viewCourrier"
                              className="nav-link active mt-1"
                              data-bs-toggle="tab"
                            >
                              Voir le courrier
                            </a>
                          </li>
                          {checkPermissions.includes(
                            "faire des annotations"
                          ) && (
                            <li>
                              <a
                                href="#comment"
                                className="nav-link mt-1"
                                data-bs-toggle="tab"
                              >
                                Voir l'annotation
                              </a>
                            </li>
                          )}
                          {checkPermissions.includes("rediger un courier") && (
                            <li>
                              <a
                                href="#answer"
                                className="nav-link mt-1"
                                data-bs-toggle="tab"
                              >
                                {data.answer ? "Voir la réponse" : "Répondre"}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="panel-body tabs-menu-body main-content-body-right border">
                      <div className="tab-content">
                        <div className="tab-pane active" id="viewCourrier">
                          <div classNameName="table-responsive">
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.js">
                              <div
                                style={{
                                  height: "750px",
                                  maxWidth: "900px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <Viewer
                                  fileUrl={url}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                          </div>
                        </div>
                        <div className="tab-pane" id="answer">
                          {data.answer ? <DisplayAnswer /> : <Answer />}
                        </div>
                        <div className="tab-pane" id="comment">
                          <div className="mt-5 mr-4">
                            {data.annotation ? (
                              <div>
                                <h3>Annotation</h3>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data.annotation.comment,
                                  }}
                                ></p>
                              </div>
                            ) : (
                              <div>
                                <h5 className="text-center">
                                  Ce courrier n'a pas d'annotation
                                </h5>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailAffectedToRedaction;
