import React, { useState } from "react";
import JsBarcode from "jsbarcode";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import API from "../../API/Network";
import moment from "moment";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "11px",
  },
  title: {
    marginBottom: "8px",
    fontWeight: "bold",
    fontSize: "11px",
    textAlign: "center",
  },

  section: {
    marginTop: 5,
    padding: 2,
    fontSize: "8px",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    fontFamily: "Times New Roman",
  },
});

const PrintAccuseReception = () => {
  const [courrier, setCourrier] = useState({});
  const [setting, setSetting] = useState({});
  const { id } = useParams();
  let canvas;

  const formattedDate = moment(courrier.created_at).format("HH:mm");

  canvas = document.createElement("canvas");
  JsBarcode(canvas, `${setting.global_ref}/${courrier.numero_accuse}`, {
    displayValue: true,
    height: 40,
  });

  const barcode = canvas.toDataURL();

  useEffect(() => {
    API.get(`/courriers/${id}`)
      .then((response) => {
        setCourrier(response.data);
        console.log(courrier);
      })
      .catch((error) => {
        console.log(error.message);
      });
    API.get("/settings")
      .then((response) => {
        setSetting(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="B8" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>Accusé Réception</Text>
            <Text stylle={{ marginLeft: "15px" }}>
              N° {setting.global_ref}/{courrier.numero_accuse}
            </Text>
            <Text>
              Par :{" "}
              {courrier.lastname +
                " " +
                courrier.middlename +
                " " +
                courrier.firstname}
            </Text>
            <Text>
              Date : {courrier.date_reception} à {formattedDate}
            </Text>
            <Image source={barcode} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PrintAccuseReception;
