import React, { useState, useEffect, useRef } from "react";
import API from "../../../API/Network";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import { baseURL } from "../../../Config/env";
import Loader from "../../Loader";

const PreviewPrivateRedaction = () => {
  const [data, setData] = useState({});
  const [setting, setSetting] = useState([]);
  const [cc, setCC] = useState([]);
  const [prefixRef, setPrefixRef] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  const ref = useRef();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    API.get("/settings")
      .then((response) => {
        setSetting(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    API.get("/prefix-refs")
      .then((response) => {
        setPrefixRef(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/get-copy-redaction-users/${id}`)
      .then((response) => {
        setCC(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    getData();
  }, [setting.logo, data.image]);

  const generatePDF = () => {
    const instance = html2pdf();

    const options = {
      margin: 10,
      filename: `courrier_${data.forwarder_lastname}_à_${data.recipient_lastname}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    instance.from(ref.current).set(options).save();
  };

  const getData = () => {
    API.get(`/show-private-redaction/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    API.post(`/redactions/signature/${data.id}`)

      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "signature a été enregistré avec succès",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        getData();
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          console.log(error.response.data.errors);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="main-content side-content pt-5">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div className="card custom-card">
                  <div
                    className="card-header border-bottom-0 pb-0"
                    ref={ref}
                    id="report"
                  >
                    <div>
                      <div className="d-flex justify-content-between">
                        {setting.logo && (
                          <img
                            src={`${baseURL}/storage/settings/${setting.logo}`}
                            alt="Logo"
                            style={{ height: "110px" }}
                          />
                        )}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <p>
                              N/Réf.:{" "}
                              <strong>
                                {prefixRef.name}/{data.count_ref}
                              </strong>
                            </p>
                            <br />
                            <br />
                            <p>
                              Concerne : <strong>{data.motif}</strong>
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="text-muted  text-end">
                              {setting.city}, le {data.date_reception}
                            </p>
                            <div className="col-md-7 offset-md-5 pt-5">
                              {cc && (
                                <div>
                                  <p className="text-decoration-underline mt-3">
                                    transmis copie pour information à
                                  </p>
                                  {cc.map((item) => (
                                    <ul>
                                      <li>
                                        {item.lastname} {item.middlename}{" "}
                                        {item.firstname}
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div
                              className="float-end text-wrap mt-5 "
                              style={{ width: "15rem" }}
                            >
                              {data.civility === "Madame"
                                ? "A Madame"
                                : "A Monsieur"}{" "}
                              {data.recipient_lastname}{" "}
                              {data.recipient_middlename}{" "}
                              {data.recipient_firstname}, <br />
                              {data.occupation}/{setting.name} {", "}
                              <p className="text-decoration-underline">
                                {"A "}
                                {setting.town}/{setting.city}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row mt-5">
                            {
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.content,
                                }}
                              ></p>
                            }
                          </div>
                          <div className="float-end">
                            {data.image ? (
                              <p>
                                {data.forwarder_lastname ? (
                                  <div>
                                    <p>
                                      {data.forwarder_lastname}{" "}
                                      {data.forwarder_lastname}{" "}
                                      {data.forwarder_lastname}
                                    </p>
                                    <img
                                      style={{ height: "50px" }}
                                      className="img-fluid"
                                      src={`${baseURL}/storage/signatures/${data.image}`}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <p>
                                    <Loader />
                                  </p>
                                )}
                              </p>
                            ) : (
                              <div class="mb-3">
                                <form
                                  class="form-group"
                                  onSubmit={handleSubmit}
                                  enctype="multipart/form-data"
                                >
                                  <label for="formFileSm" class="form-label">
                                    Veuillez valider votre signature
                                  </label>
                                  <input
                                    type="submit"
                                    class="btn btn-primary btn-sm mt-2"
                                    value="Valider"
                                  />
                                </form>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.image && (
                    <button onClick={generatePDF} className="btn btn-primary">
                      Imprimer
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PreviewPrivateRedaction;
