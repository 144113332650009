import React, { useState, useEffect, useContext } from "react";
import API from "../../API/Network";
import { useParams, Link } from "react-router-dom";
import Moment from "react-moment";
import Loader from "../Loader";
import Swal from "sweetalert2";
import JsPDF from "jspdf";
import { PermissionContext } from "../../Services/PermissionContext";
import { baseURL } from "../../Config/env";

const DisplayAnswer = () => {
  const [setting, setSetting] = useState([]);
  const [data, setData] = useState({});
  const [prefixRef, setPrefixRef] = useState({});

  const { id } = useParams();

  const checkPermissions = useContext(PermissionContext);

  useEffect(() => {
    API.get("/settings")
      .then((response) => {
        setSetting(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    getData();
  }, []);

  const getData = () => {
    API.get(`/get-courrier-answer/${id}`)
      .then((res) => {
        setData(res.data.answer);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get("/prefix-refs")
      .then((res) => {
        setPrefixRef(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const generatePDF = () => {
    const report = new JsPDF("landscape", "pt", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.save(
        `Reponse_courrier-${data.courrier.user.contact.firstname} (à ${data.courrier.expediteur}).pdf`
      );
    });
  };

  const handleSumit = (e) => {
    e.preventDefault();

    API.post(`/answer/signature/${data.id}`)

      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "signature a été enregistré avec succès et le courier a été envoyé pour impression.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        getData();
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          console.log(error.response.data.errors);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="card custom-card">
        <div className="card-header border-bottom-0 pb-0">
          <div className="d-flex justify-content-between">
            {setting.logo && (
              <img
                src={`${baseURL}/storage/settings/${setting.logo}`}
                alt="Logo"
                style={{ height: "110px" }}
              />
            )}
          </div>
        </div>
        <div className="card-body" id="report">
          <div className="row">
            <div className="col-6">
              <p>
                N/Réf. :
                <strong>
                  {prefixRef.name}/{data.reference && data.reference.count_ref}
                </strong>
              </p>
              <br />
              <br />
              <p>
                Concerne : <strong>{data.concerne}</strong>
              </p>
            </div>
            {data.courrier ? (
              <div className="col-6">
                <p className="text-muted  text-end">
                  {setting.city}, le{" "}
                  <Moment format="DD/MM/YYYY">
                    {data.courrier.date_reception}
                  </Moment>
                </p>

                <div
                  className="float-end text-wrap mt-5 "
                  style={{ width: "15rem" }}
                >
                  {data.courrier.civility === "Madame"
                    ? "A Madame la"
                    : "A Monsieur le"}{" "}
                  {data.courrier.occupation} {data.courrier.company} {", "}
                  <p className="text-decoration-underline">
                    {"A "}
                    {data.courrier.city}/{data.courrier.town}
                  </p>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
          <div className="container">
            <div className="row mt-5">
              {
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></p>
              }
            </div>
            <div className="float-end">
              {data.signature_id ? (
                <p>
                  {data.courrier.user ? (
                    <div>
                      <p>{data.courrier.user.contact.full_name}</p>
                      <img
                        style={{ height: "50px" }}
                        className="img-fluid"
                        src={`${baseURL}/storage/signatures/${data.signature.image}`}
                        alt=""
                      />
                    </div>
                  ) : (
                    <p>
                      <Loader />
                    </p>
                  )}
                </p>
              ) : (
                <div class="mb-3">
                  <form
                    class="form-group"
                    onSubmit={handleSumit}
                    enctype="multipart/form-data"
                  >
                    <label for="formFileSm" class="form-label">
                      Veuillez valider votre signature
                    </label>
                    <input
                      type="submit"
                      class="btn btn-primary btn-sm mt-2"
                      value="Valider"
                    />
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*<button onClick={generatePDF} className="btn btn-primary">
          Imprimer
              </button>*/}
        {checkPermissions.includes("modifier un courrier redigé") && (
          <Link
            to={`/answer-edit/${data.id}`}
            className="btn col-md-4 offset-md-4 btn-link"
          >
            cliquer pour modifier
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

export default DisplayAnswer;
