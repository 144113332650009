import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";

const EditPermission = () => {
  const [name, setName] = useState("");
  const [oldData, setOldData] = useState("");
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
    setOldData(e.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    const id = params.id;

    API.get(`/permissions/${id}`).then((res) => {
      setOldData(res.data.data);
      console.log(oldData.name);
    });
  }, []);

  const handleSumit = (e) => {
    e.preventDefault();

    const id = params.id;
    API.put(`/permissions/${id}`, {
      name: name,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Permission modifiée avec succès.",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };
  if (redirect) {
    navigate("/permissions");
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="main-content side-content pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Modifier une permission
                        </h6>
                        <Link
                          to="/permissions"
                          className="text-muted card-sub-title text-primary"
                        >
                          Voir la liste des permissions{" "}
                        </Link>
                      </div>
                      <form onSubmit={handleSumit}>
                        <div className="text-center">
                          <div className="form-group col-md-4 offset-md-4">
                            <input
                              value={oldData.name}
                              onChange={handleNameChange}
                              className={`form-control ${
                                errors && errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Saisissez un nom"
                              type="text"
                            />
                            {errors && errors.name ? (
                              <div className="text-danger">
                                {errors["name"]}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <p classNameName="text-center">
                            <button className="text-center btn btn-primary">
                              Modifier
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default EditPermission;
