import React, { useState, useEffect, useContext } from "react";
import API from "../../API/Network";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import JsPDF from "jspdf";
import { PermissionContext } from "../../Services/PermissionContext";
import DataTable from "react-data-table-component";
import customStyles from "../../utils/datatables/customStyles";
import OutAnswer from "./OutAnswer";

const OutgoingMail = () => {
  const [mails, setMails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [prefixRef, setPrefixRef] = useState({});
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const permissions = useContext(PermissionContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    API.get("/outgoing-mails")
      .then((res) => {
        setMails(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
    API.get("/prefix-refs")
      .then((res) => {
        setPrefixRef(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = mails.filter(
    (item) =>
      item.motif.toLowerCase().includes(searchText.toLowerCase()) ||
      item.reference.count_ref.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Numéro référence",
      selector: "count_ref",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          {prefixRef.name}/{row.reference.count_ref}
        </div>
      ),
    },
    {
      name: "Date réception",
      selector: "date_reception",
      sortable: true,
      cell: (row) => <div>{row.date_reception}</div>,
    },

    {
      name: "Motif",
      selector: "motif",
      sortable: true,
      width: "300px",
    },

    {
      name: "expediteur",
      selector: "expediteur",
      sortable: true,
      width: "300px",
      cell: (row) => <div>{row.expediteur.contact.full_name}</div>,
    },
    {
      name: "Destinataire",
      selector: "destinataire",
      sortable: true,
      width: "300px",
    },

    {
      name: "Action",
      width: "120px",
      cell: (row) => (
        <div className="d-flex">
          {permissions.includes("imprimer un courier sortant") && (
            <Link to={`/print-redaction/${row.id}`} className="btn btn-primary">
              Imprimer
            </Link>
          )}
        </div>
      ),
    },
  ];

  const generatePDF = () => {
    const report = new JsPDF("landscape", "pt", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.save(
        `courrier_${mails.expediteur.contact.firstname} (à ${mails.destinataire}).pdf`
      );
    });
  };

  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Courriers sortants
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste des courriers sortants
                  </li>
                </ol>
              </div>
            </div>
            <div class="card custom-card mg-b-20" id="tabs-style2">
              <div class="card-body">
                <div class="text-wrap">
                  <div class="example">
                    <div class="panel panel-primary tabs-style-2">
                      <div class=" tab-menu-heading">
                        <div class="tabs-menu1">
                          <ul class="nav panel-tabs main-nav-line">
                            <li>
                              <a
                                href="#redaction"
                                class="nav-link active mt-1"
                                data-bs-toggle="tab"
                              >
                                Lettres adressées
                              </a>
                            </li>
                            <li>
                              <a
                                href="#answer"
                                class="nav-link mt-1"
                                data-bs-toggle="tab"
                              >
                                Lettres répondues
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="panel-body tabs-menu-body main-content-body-right border">
                        <div class="tab-content">
                          <div class="tab-pane active" id="redaction">
                            <div className="col-4 mb-3">
                              <input
                                type="text"
                                className="form-control border-end-0 border rounded-pill"
                                placeholder="Recherche..."
                                onChange={handleSearch}
                              />
                            </div>
                            <div className="table-responsive">
                              <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination={true}
                                progressPending={isLoading}
                                progressComponent={<Loader />}
                                fixedHeader={true}
                                striped
                                highlightOnHover
                                pointerOnHover
                                responsive
                                customStyles={customStyles}
                                noDataComponent={
                                  <div className="alert alert-warning">
                                    Il n'y a pas d'enregistrements à afficher
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          <div class="tab-pane" id="answer">
                            <OutAnswer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OutgoingMail;
