import axios from "axios";
import { baseURL } from "../Config/env";
export default axios.create({
  baseURL: baseURL + "/api/ebutelo",

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + localStorage.getItem("token") ?? "",
  },
});
