import React, { useState, useEffect } from "react";
import API from "../../API/Network";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

const HistoryCourrier = () => {
  const [receipt, setReceipt] = useState({});
  const [affect, setAffect] = useState({});
  const [annotation, setAnnotation] = useState({});
  const [affectToRedaction, setAffectToRedaction] = useState({});
  const [answer, setAnswer] = useState({});
  const [signature, setSignature] = useState({});

  const { id } = useParams();

  useEffect(() => {
    API.get(`/history/receipt-courrier/${id}`)
      .then((response) => {
        setReceipt(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/history/affectation/${id}`)
      .then((response) => {
        console.log(response.data);
        setAffect(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    API.get(`/history/annotation/${id}`)
      .then((response) => {
        console.log(response.data);
        setAnnotation(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/history/affect-to-redaction/${id}`)
      .then((response) => {
        console.log(response.data);
        setAffectToRedaction(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/history/answer/${id}`)
      .then((response) => {
        console.log(response.data);
        setAnswer(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    API.get(`/history/signature/${id}`)
      .then((response) => {
        console.log(response.data);
        setSignature(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Historique courrier
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dasboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Suivi courrier
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-sm">
          <div class="col-lg-12">
            <div class="card custom-card">
              <div class="card-body">
                <div class="vtimeline">
                  <div class="timeline-wrapper timeline-wrapper-primary">
                    <div class="timeline-badge"></div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h6 class="timeline-title">Accusé réception</h6>
                      </div>
                      <div class="timeline-body">
                        <p>
                          le courrier a été réceptionné par : <br />{" "}
                          <span className="text-primary">
                            {receipt.lastname +
                              " " +
                              receipt.middlename +
                              " " +
                              receipt.firstname}
                          </span>
                        </p>
                      </div>
                      <div class="timeline-footer d-flex align-items-center flex-wrap">
                        <span class="ms-md-auto">
                          <i class="fe fe-calendar text-muted me-1"></i>
                          <Moment format="DD/MM/YYYY">
                            {receipt.created_at}
                          </Moment>{" "}
                          à<Moment format="HH:mm">{receipt.created_at}</Moment>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  {affect.lastname && (
                    <div class="timeline-wrapper timeline-inverted timeline-wrapper-secondary">
                      <div class="timeline-badge"></div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <h6 class="timeline-title">Affectation</h6>
                        </div>
                        <div class="timeline-body">
                          <p>
                            le courrier a été affecté à : <br />{" "}
                            <span className="text-primary">
                              {affect.lastname +
                                " " +
                                affect.middlename +
                                " " +
                                affect.firstname}
                            </span>{" "}
                          </p>
                        </div>
                        <div class="timeline-footer d-flex align-items-center flex-wrap">
                          <span class="ms-md-auto">
                            <i class="fe fe-calendar text-muted me-1"></i>
                            <Moment format="DD/MM/YYYY">
                              {affect.created_at}
                            </Moment>{" "}
                            à<Moment format="HH:mm">{affect.created_at}</Moment>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {annotation.lastname && (
                    <div class="timeline-wrapper timeline-wrapper-info">
                      <div class="timeline-badge"></div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <h6 class="timeline-title">Annotation</h6>
                        </div>
                        <div class="timeline-body">
                          <p>
                            les annotations de ce courrier ont été faites par :{" "}
                            <br />{" "}
                            <span className="text-primary">
                              {annotation.lastname +
                                " " +
                                annotation.middlename +
                                " " +
                                annotation.firstname}
                            </span>{" "}
                          </p>
                        </div>
                        <div class="timeline-footer d-flex align-items-center flex-wrap">
                          <span class="ms-md-auto">
                            <i class="fe fe-calendar text-muted me-1"></i>
                            <Moment format="DD/MM/YYYY">
                              {annotation.created_at}
                            </Moment>{" "}
                            à
                            <Moment format="HH:mm">
                              {annotation.created_at}
                            </Moment>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {affectToRedaction.lastname && (
                    <div class="timeline-wrapper timeline-inverted timeline-wrapper-danger">
                      <div class="timeline-badge"></div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <h6 class="timeline-title">
                            affecté pour la rédaction
                          </h6>
                        </div>
                        <div class="timeline-body">
                          <p>
                            le courrier a été affecté pour la rédaction à :{" "}
                            <br />
                            <span className="text-primary">
                              {affectToRedaction.lastname +
                                " " +
                                affectToRedaction.middlename +
                                " " +
                                affectToRedaction.firstname}
                            </span>{" "}
                          </p>
                        </div>
                        <div class="timeline-footer d-flex align-items-center flex-wrap">
                          <span class="ms-md-auto">
                            <i class="fe fe-calendar text-muted me-1"></i>
                            <Moment format="DD/MM/YYYY">
                              {affectToRedaction.updated_at}
                            </Moment>{" "}
                            à
                            <Moment format="HH:mm">
                              {affectToRedaction.updated_at}
                            </Moment>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {answer.id && (
                    <div class="timeline-wrapper timeline-wrapper-success">
                      <div class="timeline-badge"></div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <h6 class="timeline-title">Courrier répondu</h6>
                        </div>
                        <div class="timeline-body">
                          <p>le courrier a été répondu</p>
                        </div>
                        <div class="timeline-footer d-flex align-items-center flex-wrap">
                          <span class="ms-md-auto">
                            <i class="fe fe-calendar text-muted me-1"></i>
                            <Moment format="DD/MM/YYYY">
                              {answer.created_at}
                            </Moment>{" "}
                            à<Moment format="HH:mm">{answer.created_at}</Moment>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {signature.id && (
                    <div class="timeline-wrapper timeline-inverted timeline-wrapper-warning">
                      <div class="timeline-badge"></div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <h6 class="timeline-title">Signature</h6>
                        </div>
                        <div class="timeline-body">
                          <p>le courrier a été signé et imprimé</p>
                        </div>
                        <div class="timeline-footer d-flex align-items-center flex-wrap">
                          <span class="ms-md-auto">
                            <i class="fe fe-calendar text-muted me-1"></i>
                            <Moment format="DD/MM/YYYY">
                              {signature.updated_at}
                            </Moment>{" "}
                            à
                            <Moment format="HH:mm">
                              {signature.updated_at}
                            </Moment>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HistoryCourrier;
