import React, { useContext, useEffect, useState } from "react";
import API from "../../API/Network";

import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../Services/PermissionContext";

const Dashboard = () => {
  const [courierCount, setCourrierCount] = useState([]);
  const [OutgoingMailCount, setOutgoingMailCount] = useState([]);
  const [redactionCount, setRedactionCount] = useState([]);
  const [traitementCourrierCount, setTraitementCourrierCount] = useState([]);

  const navigate = useNavigate();

  const checkPermissions = useContext(PermissionContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }

    API.get("/courriers").then((res) => {
      setCourrierCount(res.data.data);
    });
    //outgoing mail
    API.get("/outgoing-mails").then((res) => {
      setOutgoingMailCount(res.data);
    });

    API.get("/redactions").then((res) => {
      setRedactionCount(res.data);
    });

    API.get("/mail-a-traiter").then((res) => {
      setTraitementCourrierCount(res.data);
    });
  }, []);

  const ColSize = checkPermissions.includes("lire courier entrant")
    ? "col-sm-12 col-md-6 col-lg-6 col-xl-3"
    : "col-sm-12 col-md-6 col-lg-6 col-xl-4";
  return (
    <React.Fragment>
      <div className="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div class="page-header">
            <div>
              <h2 class="main-content-title tx-24 mg-b-5">Administration</h2>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Dashboard</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="row row-sm">
            {checkPermissions.includes("lire courier entrant") && (
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div class="card custom-card">
                  <div class="card-body">
                    <div class="card-order">
                      <label class="main-content-label mb-3 pt-1">
                        Couriers entrants
                      </label>
                      <h2 class="text-end">
                        <i class="fe fe-mail icon-size float-start text-primary"></i>
                        <span class="font-weight-bold">
                          {courierCount.length}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div class={ColSize}>
              <div class="card custom-card">
                <div class="card-body">
                  <div class="card-order">
                    <label class="main-content-label mb-3 pt-1">
                      Courrier à traiter
                    </label>
                    <h2 class="text-end">
                      <i class="fe fe-file-text   float-start text-primary"></i>
                      <span class="font-weight-bold">
                        {traitementCourrierCount.length}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class={ColSize}>
              <div class="card custom-card">
                <div class="card-body">
                  <div class="card-order">
                    <label class="main-content-label mb-3 pt-1">
                      Redactions
                    </label>
                    <h2 class="text-end">
                      <i class="mdi mdi-cube icon-size float-start text-primary"></i>
                      <span class="font-weight-bold">
                        {redactionCount.length}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class={ColSize}>
              <div class="card custom-card">
                <div class="card-body">
                  <div class="card-order ">
                    <label class="main-content-label mb-3 pt-1">
                      Courriers sortants
                    </label>
                    <h2 class="text-end card-item-icon card-icon">
                      <i class="fe fe-navigation icon-size float-start text-primary"></i>
                      <span class="font-weight-bold">
                        {OutgoingMailCount.length}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
