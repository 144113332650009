import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import API from "../../../API/Network";
import Loader from "../../Loader";
import RedactionReceived from "../../Redaction/Private/RedactionReceived";
import { PermissionContext } from "../../../Services/PermissionContext";

const Directory = () => {
  const [directories, setDirectories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const checkPermissions = useContext(PermissionContext);

  useEffect(() => {
    API.get("/courrier-directories")
      .then((response) => {
        setDirectories(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <React.Fragment>
      <div class="main-content side-content pt-0">
        <div class="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Courriers entrants
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Courriers
                  </li>
                </ol>
              </div>
              <div className="d-flex"></div>
            </div>
            <div class="row row-sm">
              <div class="col-lg-12">
                <div class="card custom-card mg-b-20" id="tabs-style2">
                  <div class="card-body">
                    <div class="text-wrap">
                      <div class="example">
                        <div class="panel panel-primary tabs-style-2">
                          <div class=" tab-menu-heading">
                            <div class="tabs-menu1">
                              <ul class="nav panel-tabs main-nav-line">
                                <li>
                                  <a
                                    href="#outside"
                                    class="nav-link active mt-1"
                                    data-bs-toggle="tab"
                                  >
                                    Courriers externes
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#inside"
                                    class="nav-link mt-1"
                                    data-bs-toggle="tab"
                                  >
                                    Courriers internes
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="panel-body tabs-menu-body main-content-body-right border">
                            <div class="tab-content">
                              <div class="tab-pane active" id="outside">
                                <div className="table-responsive">
                                  <div className="row">
                                    {isLoading ? (
                                      <div>
                                        <Loader />
                                      </div>
                                    ) : (
                                      directories.map((item, index) => (
                                        <div
                                          key={index}
                                          class="col-xl-3 col-md-4 col-sm-6"
                                        >
                                          <div class="card custom-card border p-0 shadow-none">
                                            <div class="d-flex align-items-center px-4 pt-2">
                                              <label class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  name="example-checkbox2"
                                                  value="option2"
                                                />
                                                <span class="custom-control-label"></span>
                                              </label>
                                            </div>
                                            <div class="card-body pt-0 text-center">
                                              <Link
                                                to={`/directories/${item.id}/subdirectories`}
                                                class="open-file"
                                              >
                                                <div class="file-manger-icon">
                                                  <i className="fe fe-folder fe-folder tx-30 bg-primary p-4 rounded-5"></i>
                                                </div>
                                                <h6 class="mb-1 mt-4 font-weight-semibold mt-0">
                                                  {item.name}
                                                </h6>
                                                <span class="text-muted">
                                                  sous dossiers(
                                                  {item.nbr_sub_directories})
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="inside">
                                <RedactionReceived />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Directory;
