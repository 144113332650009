import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../../Config/env";

const CreateCourrier = () => {
  const [motif, setMotif] = useState("");
  const [doc, setDoc] = useState();
  const [expediteur, setExpediteur] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [destinataire, setDestinataire] = useState([]);
  const [observation, setObservation] = useState("");
  const [civilite, setCivilite] = useState("");
  const [societe, setSociete] = useState("");
  const [occupation, setOccupation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adress, setAdress] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [errors, setErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleMotifChange = (event) => {
    setMotif(event.target.value);
  };

  const handleObservationChange = (event) => {
    setObservation(event.target.value);
  };

  const handleCiviliteChange = (event) => {
    setCivilite(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleSocieteChange = (event) => {
    setSociete(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAdress(event.target.value);
  };

  const handleTownChange = (event) => {
    setTown(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleDocChange = (event) => {
    setDoc(event.target.files[0]);
  };

  const handleDestinataireChange = (event) => {
    setSelectValue(event.target.value);
  };

  const handleExpediteurChange = (event) => {
    setExpediteur(event.target.value);
  };

  const options = [
    { value: "Normal", label: "Normal" },
    { value: "Prioritaire", label: "Prioritaire" },
    { value: "Urgent", label: "Urgent" },
  ];

  const civilities = [
    { value: "Monsieur", label: "Monsieur" },
    { value: "Madame", label: "Madame" },
    { value: "Docteur", label: "Docteur" },
  ];

  const token = localStorage.getItem("token");

  useEffect(() => {
    let headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseURL + "/api/ebutelo/users", headers)
      .then((res) => {
        setDestinataire(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    if (!token) {
      navigate("/");
    }
  }, []);

  const handleSumit = (e) => {
    e.preventDefault();

    let bodyFormData = new FormData();

    bodyFormData.set("motif", motif);
    bodyFormData.set("observation", observation);
    bodyFormData.set("destinataire", selectValue);
    bodyFormData.set("expediteur", expediteur);
    bodyFormData.set("doc", doc);
    bodyFormData.set("civility", civilite);
    bodyFormData.set("occupation", occupation);
    bodyFormData.set("company", societe);
    bodyFormData.set("email", email);
    bodyFormData.set("phone", phone);
    bodyFormData.set("address", adress);
    bodyFormData.set("town", town);
    bodyFormData.set("city", city);
    bodyFormData.set("country", country);
    bodyFormData.set("courrier_directory_id", id);

    let headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseURL + "/api/ebutelo/courriers", bodyFormData, headers)
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "courrieer créé avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  if (redirect) {
    navigate(`/subdirectories/${id}/courriers`);
  }

  return (
    <React.Fragment>
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-card">
              <div class="card-body">
                <form
                  class="form-group"
                  onSubmit={handleSumit}
                  enctype="multipart/form-data"
                >
                  <div class="row row-sm">
                    <div class="col-lg-12 col-md-12">
                      <div class="card custom-card">
                        <div class="card-body">
                          <div class="row row-sm">
                            <div class="col-md-12 col-lg-12 col-xl-12">
                              <div class="">
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <h4 className="text-center mb-3 text-primary">
                                      Informations relatives au courrier
                                    </h4>
                                    <div class="col-sm-6">
                                      <label class="">Concerne</label>
                                      <input
                                        onChange={handleMotifChange}
                                        class={`form-control ${
                                          errors && errors.motif
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Entrez l'objet de la lettre"
                                        type="text"
                                      />
                                      {errors && errors.motif ? (
                                        <div className="text-danger">
                                          {errors["motif"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-6 mg-t-20 mg-sm-t-0">
                                      <label class="">Priorité</label>
                                      <select
                                        class={`form-control select select2 ${
                                          errors && errors.observation
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        value={observation}
                                        onChange={handleObservationChange}
                                      >
                                        <option value="">
                                          Séléctionner une priorité
                                        </option>
                                        {options.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                      {errors && errors.observation ? (
                                        <div className="text-danger">
                                          {errors["observation"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-6 mg-t-20 mg-sm-t-0">
                                      <label class="">Destinataire</label>
                                      <select
                                        class={`form-control select select2 ${
                                          errors && errors.destinataire
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        onChange={handleDestinataireChange}
                                      >
                                        <option value="">
                                          Sélectionner un destinataire
                                        </option>
                                        {destinataire.map((user) => (
                                          <option value={user.id}>
                                            {user.contact.full_name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors && errors.destinataire ? (
                                        <div className="text-danger">
                                          {errors["destinataire"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-6 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        Document{" "}
                                        <span className="text-muted">
                                          (PDF)
                                        </span>
                                      </label>
                                      <input
                                        onChange={handleDocChange}
                                        class={`form-control ${
                                          errors && errors.doc
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        type="file"
                                      />
                                      {errors && errors.doc ? (
                                        <div className="text-danger">
                                          {errors["doc"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group mt-5">
                                  <div class="row row-sm">
                                    <h4 className="text-center mb-3 text-primary">
                                      Informations relatives à l'Expéditeur
                                    </h4>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Civilité</label>
                                      <select
                                        className={`form-control select select2 ${
                                          errors && errors.civility
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        onChange={handleCiviliteChange}
                                      >
                                        <option value="">
                                          Sélectionner une civilité
                                        </option>
                                        {civilities.map((civility) => (
                                          <option
                                            key={civility.value}
                                            value={civility.value}
                                          >
                                            {civility.label}
                                          </option>
                                        ))}
                                      </select>
                                      {errors && errors.civility ? (
                                        <div className="text-danger">
                                          {errors["civility"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4">
                                      <label class="">Noms</label>
                                      <input
                                        onChange={handleExpediteurChange}
                                        class={`form-control ${
                                          errors && errors.expediteur
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="nom complet de la personne qui a envoyé la lettre"
                                        type="text"
                                      />
                                      {errors && errors.expediteur ? (
                                        <div className="text-danger">
                                          {errors["expediteur"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Télephone</label>
                                      <input
                                        onChange={handlePhoneChange}
                                        class={`form-control ${
                                          errors && errors.phone
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Numéro de télephone de l'expéditeur"
                                        type="tel"
                                      />
                                      {errors && errors.company ? (
                                        <div className="text-danger">
                                          {errors["phone"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <div class="col-sm-4">
                                      <label class="">Adresse physique</label>
                                      <input
                                        onChange={handleAddressChange}
                                        class={`form-control ${
                                          errors && errors.address
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Adresse de l'expéditeur"
                                        type="text"
                                      />
                                      {errors && errors.company ? (
                                        <div className="text-danger">
                                          {errors["address"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4">
                                      <label class="">Commune</label>
                                      <input
                                        onChange={handleTownChange}
                                        class={`form-control ${
                                          errors && errors.town
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="le nom de la commune"
                                        type="text"
                                      />
                                      {errors && errors.company ? (
                                        <div className="text-danger">
                                          {errors["town"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-4 mg-t-20 mg-sm-t-0">
                                      <label class="">Ville</label>
                                      <input
                                        onChange={handleCityChange}
                                        class={`form-control ${
                                          errors && errors.city
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Nom de la ville. ex: Kinshasa"
                                        type="text"
                                      />
                                      {errors && errors.company ? (
                                        <div className="text-danger">
                                          {errors["city"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="row row-sm">
                                    <div class="col-sm-3">
                                      <label class="">
                                        Entreprise{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        onChange={handleSocieteChange}
                                        class={`form-control ${
                                          errors && errors.company
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="le nom de l\'entreprise"
                                        type="text"
                                      />
                                      {errors && errors.company ? (
                                        <div className="text-danger">
                                          {errors["company"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-3">
                                      <label class="">
                                        Fonction
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        onChange={handleOccupationChange}
                                        class={`form-control ${
                                          errors && errors.occupation
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Le poste qu'occupe l'expéditeur"
                                        type="text"
                                      />
                                      {errors && errors.occupation ? (
                                        <div className="text-danger">
                                          {errors["occupation"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-3 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        Email{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        onChange={handleEmailChange}
                                        class={`form-control ${
                                          errors && errors.phone
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Email pour la réponse du courrier"
                                        type="email"
                                      />
                                      {errors && errors.email ? (
                                        <div className="text-danger">
                                          {errors["email"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-sm-3 mg-t-20 mg-sm-t-0">
                                      <label class="">
                                        Pays{" "}
                                        <span className="text-muted">
                                          (facultatif)
                                        </span>
                                      </label>
                                      <input
                                        onChange={handleCountryChange}
                                        class={`form-control ${
                                          errors && errors.country
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Nom du pays. ex: RDC"
                                        type="text"
                                      />
                                      {errors && errors.country ? (
                                        <div className="text-danger">
                                          {errors["country"]}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <button class="btn ripple btn-main-primary btn-block">
                                  Enregistrer
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateCourrier;
