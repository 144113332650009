import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";

const EditUser = () => {
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setrRoles] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [errors, setErrors] = useState("");
  const [gender, setGender] = useState("");
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };

  const handleMiddlenameChange = (event) => {
    setMiddlename(event.target.value);
  };

  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectValue(e.target.value);
    console.log(selectValue);
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
    console.log(selectValue);
  };
  const options = [
    { value: "Homme", label: "Homme" },
    { value: "Femme", label: "Femme" },
  ];

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    API.get(`/users/${id}`).then((res) => {
      let userData = res.data.data;

      setLastname(userData.contact.lastname);
      setMiddlename(userData.contact.middlename);
      setFirstname(userData.contact.firstname);
      setPhone(userData.contact.phone);
      setEmail(userData.email);
      setGender(userData.contact.gender);
      console.log(userData);
    });
  }, []);

  const handleSumit = (e) => {
    e.preventDefault();

    API.patch(`/users/${id}`, {
      lastname: lastname,
      middlename: middlename,
      firstname: firstname,
      phone: phone,
      email: email,
      gender: gender,
      role: selectValue,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Utilisateur Modifié avec  succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };

  useEffect(() => {
    API.get("/roles")
      .then((res) => {
        setrRoles(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  if (redirect) {
    navigate("/users");
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="main-content side-content pt-0">
          <div className="main-container container-fluid">
            <div class="col-lg-12 col-md-12">
              <div class="card custom-card">
                <div class="card-body">
                  <form class="form-group" onSubmit={handleSumit}>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Nom</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <input
                          value={lastname}
                          onChange={handleLastnameChange}
                          class={`form-control ${
                            errors && errors.lastname ? "is-invalid" : ""
                          }`}
                          placeholder="Entrez le nom"
                          type="text"
                        />
                        {errors && errors.lastname ? (
                          <div className="text-danger">
                            {errors["lastname"]}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Postnom</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <input
                          value={middlename}
                          onChange={handleMiddlenameChange}
                          class={`form-control ${
                            errors && errors.middlename ? "is-invalid" : ""
                          }`}
                          placeholder="Entrez le postnom"
                          type="text"
                        />
                        {errors && errors.middlename ? (
                          <div className="text-danger">
                            {errors["middlename"]}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Prénom</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <input
                          value={firstname}
                          onChange={handleFirstnameChange}
                          class={`form-control ${
                            errors && errors.firstname ? "is-invalid" : ""
                          }`}
                          placeholder="Entrez le prénom"
                          type="text"
                        />
                        {errors && errors.firstname ? (
                          <div className="text-danger">
                            {errors["firstname"]}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Email</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <input
                          value={email}
                          onChange={handleEmailChange}
                          class={`form-control ${
                            errors && errors.email ? "is-invalid" : ""
                          }`}
                          placeholder="Enter your email"
                          type="email"
                        />
                        {errors && errors.email ? (
                          <div className="text-danger">{errors["email"]} </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Sexe</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <select
                          className="form-control"
                          value={gender}
                          onChange={handleGenderChange}
                        >
                          <option>Sélectionner le sexe</option>

                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Téléphone</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <input
                          value={phone}
                          onChange={handlePhoneChange}
                          class={`form-control ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                          placeholder="Entrez le numéro de téléphone"
                          type="tel"
                        />
                        {errors && errors.phone ? (
                          <div className="text-danger">{errors["phone"]} </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-4">
                        <label class="mg-b-0">Role</label>
                      </div>
                      <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <select
                          onChange={handleRoleChange}
                          class="form-control"
                        >
                          <option>Sélectionner un role</option>
                          {roles.map((role) => (
                            <option value={role.id}>{role.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="form-group row justify-content-end mb-0">
                      <div class="col-md-8 ps-md-2">
                        <button class="btn ripple btn-primary pd-x-30 mg-r-5">
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default EditUser;
