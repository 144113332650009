import React, { Fragment, useState } from "react";
import API from "../../../API/Network";
import Swal from "sweetalert2";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.post("/change-password", {
      current_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    })
      .then((res) => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setErrors("");

        Swal.fire({
          title: "Success",
          type: "success",
          text: "Mot de passe changé avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };
  return (
    <Fragment>
      <div class="card-body border" data-select2-id="12">
        <form
          onSubmit={handleSubmit}
          class="form-horizontal"
          data-select2-id="11"
        >
          <div class="mb-4 main-content-label">Changement de mot de passe</div>
          <div class="form-group ">
            <div class="row row-sm">
              <div class="col-md-3">
                <label class="form-label">Ancien mot de passe</label>
              </div>
              <div class="col-md-9">
                <input
                  onChange={handleOldPasswordChange}
                  type="password"
                  class={`form-control ${
                    errors && errors.current_password ? "is-invalid" : ""
                  }`}
                  placeholder="Ancien mot de passe"
                />
                {errors && errors.current_password ? (
                  <div className="text-danger">
                    {errors["current_password"]}{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div class="form-group ">
            <div class="row row-sm">
              <div class="col-md-3">
                <label class="form-label">Nouveau Mot de passe</label>
              </div>
              <div class="col-md-9">
                <input
                  onChange={handleNewPasswordChange}
                  type="password"
                  class={`form-control ${
                    errors && errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Entrez le nouveau Mot de passe"
                />
                {errors && errors.password ? (
                  <div className="text-danger">{errors["password"]} </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div class="form-group " data-select2-id="108">
            <div class="row" data-select2-id="107">
              <div class="col-md-3">
                <label class="form-label">
                  Confirmer le nouveau mot de passe
                </label>
              </div>
              <div class="col-md-9" data-select2-id="106">
                <input
                  onChange={handleConfirmPasswordChange}
                  type="password"
                  class={`form-control ${
                    errors && errors.password_confirmation ? "is-invalid" : ""
                  }`}
                  placeholder="Confirmer le nouveau mot de passe"
                />
                {errors && errors.password_confirmation ? (
                  <div className="text-danger">
                    {errors["password_confirmation"]}{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="text-start mt-5 ms-0">
            {errors && errors === "incorect_password" ? (
              <div className="alert alert-warning">Mot de passe incorrect </div>
            ) : (
              ""
            )}
          </div>
          <button className="btn btn-primary offset-4">Enregistrer</button>
        </form>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
