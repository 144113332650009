import React, { Fragment } from "react";
import Moment from "react-moment";

const MyInfo = ({ myInfo }) => {
  return (
    <Fragment>
      <div class="card-body p-0 border p-0 rounded-10">
        <div class="p-4">
          <h4 class="tx-15 text-uppercase mb-3">Nom d'utilisateur</h4>
          <p class="m-b-5">{myInfo.username}</p>
          <div class="m-t-30">
            <h4 class="tx-15 text-uppercase mt-3">Nom complet</h4>
            <div class=" p-t-10">
              <h5 class="text-primary m-b-5 tx-14">
                {myInfo.contact && myInfo.contact.full_name}
              </h5>
              <p class="">Sexe : {myInfo.contact && myInfo.contact.gender}</p>
              <h4 class="tx-15 text-uppercase mb-3">
                Date de création de compte
              </h4>
              <p class="m-b-5">
                <Moment format="DD/MM/YYYY">{myInfo.created_at}</Moment>
              </p>
            </div>
          </div>
        </div>
        <div class="border-top"></div>
        <div class="p-4">
          <label class="main-content-label tx-13 mg-b-20">Contact</label>
          <div class="d-sm-flex">
            <div class="mg-sm-r-20 mg-b-10">
              <div class="main-profile-contact-list">
                <div class="media">
                  <div class="media-icon bg-primary-transparent text-primary">
                    {" "}
                    <i class="icon ion-md-phone-portrait"></i>{" "}
                  </div>
                  <div class="media-body">
                    {" "}
                    <span>Téléphone</span>
                    <div>
                        {
                            myInfo.contact && myInfo.contact.phone
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mg-sm-r-20 mg-b-10">
              <div class="main-profile-contact-list">
                <div class="media">
                  <div class="media-icon bg-success-transparent text-success">
                    {" "}
                    <i class="fe fe-mail"></i>{" "}
                  </div>
                  <div class="media-body">
                    {" "}
                    <span>Email</span>
                    <div>{myInfo.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="main-profile-contact-list">
                <div class="media">
                  <div class="media-icon bg-info-transparent text-info">
                    {" "}
                    <i class="icon ion-md-locate"></i>{" "}
                  </div>
                  <div class="media-body">
                    {" "}
                    <span>Adresse</span>
                    <div>{myInfo.contact && myInfo.contact.address}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-top"></div>
      </div>
    </Fragment>
  );
};

export default MyInfo;
