import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../API/Network";
import Select from "react-select";

const AffectCourrierToRedaction = ({ courrier }) => {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState();
  const [errors, setErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const options = users.map((user) => ({
    label: user.lastname + " " + user.middlename + " " + user.firstname,
    value: user.id,
  }));

  useEffect(() => {
    API.get("/allUsers-without-auth").then((res) => {
      setUsers(res.data);
      console.log(res.data);
    });

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    API.patch(`/affect-courrier-to-redaction/${id}`, {
      affect_to: selected.value,
    })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "ce courrier a été  affecté pour la rédaction",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });

        setRedirect(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
      });
  };
  if (redirect) {
    navigate("/courrierATraiter");
  }

  return (
    <div className="card-body">
      <form onSubmit={handleSubmit}>
        <div className="text-center">
          <div className="form-group col-md-4 offset-md-4">
            <Select options={options} value={selected} onChange={setSelected} />
          </div>
          <p classNameName="text-center">
            <button className="text-center btn btn-primary">Affecter</button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default AffectCourrierToRedaction;
